import {trpc} from '@/api/trpcClient';
import {zodResolver} from '@hookform/resolvers/zod';
import {i18n} from '@lingui/core';
import {Trans, t} from '@lingui/macro';
import {
  AlertOverlayWithConfirmation,
  Button,
  InputText,
  SlideOverWithBrandedHeader,
  useNotification,
} from '@zentact/ui-tailwind';
import {useCallback, useState} from 'react';
import {useForm} from 'react-hook-form';
import {AddApiKeyForm, ApiKeyFormData, addApiKeyFormSchema} from './add-api-key-form';

type AddApiKeyProps = {
  isOpen: boolean;
  onSuccess: () => void;
  onCancel: () => void;
};

export const AddApiKey = ({isOpen, onSuccess, onCancel}: AddApiKeyProps) => {
  const [addApiKeyConfirmationOpen, setAddApiKeyConfirmationOpen] = useState(false);
  const {showSuccessNotification, showErrorNotification} = useNotification();
  const [apiKey, setApiKey] = useState('');

  const addApiKeyMutation = trpc.apiKeys.addApiKey.useMutation({
    onSuccess: () => {
      showSuccessNotification(
        t`New API Key is created`,
        t`Now you can copy this key to your clipboard`
      );
      onSuccess();
    },
    onError: error => {
      onCancel();
      showErrorNotification(i18n._('Error'), error.message);
    },
  });

  const form = useForm<ApiKeyFormData>({
    resolver: zodResolver(addApiKeyFormSchema()),
  });

  const {handleSubmit} = form;

  const onSubmit = useCallback(async (data: ApiKeyFormData) => {
    const newApiKey = await addApiKeyMutation.mutateAsync({
      name: data.name,
    });
    setApiKey(newApiKey.apiKey);
    setAddApiKeyConfirmationOpen(true);
  }, []);

  const onCancelClick = useCallback((_event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onCancel();
    form.reset();
  }, []);

  const copyApiKey = () => {
    navigator.clipboard.writeText(apiKey);
    showSuccessNotification(t`New API Key copied`);
  };

  return (
    <>
      <SlideOverWithBrandedHeader
        isOpen={isOpen}
        title={t`Create new API Key`}
        closeHandler={onCancelClick}
        footer={
          <footer className="flex flex-row-reverse p-4 shrink-0 gap-x-3">
            <div className="flex shrink-0 gap-x-3">
              <Button
                variant="primary"
                size="lg"
                className="w-fit"
                onClick={handleSubmit(onSubmit)}
              >
                <Trans>Create New Key</Trans>
              </Button>
            </div>
            <Button variant="secondary" size="lg" className="w-fit" onClick={onCancelClick}>
              <Trans>Close</Trans>
            </Button>
          </footer>
        }
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <AddApiKeyForm form={form} />
        </form>
      </SlideOverWithBrandedHeader>
      <AlertOverlayWithConfirmation
        open={addApiKeyConfirmationOpen}
        setOpen={setAddApiKeyConfirmationOpen}
        handleAction={copyApiKey}
        disableConfirmButton={false}
        mode="success"
        localeText={{
          title: i18n._('Save your key'),
          description: i18n._(
            `Please save this secret key somewhere safe and accessible. For security reasons, you won't be able to view it again through your Zentact account. If you lose this secret key, you'll need to generate a new one.`
          ),
          confirm: i18n._('Copy'),
          cancel: i18n._('Done'),
        }}
      >
        <div className="px-4">
          <InputText
            className="text-gray-400 placeholder:text-gray-400 sm:text-sm"
            readOnly={true}
            value={apiKey}
          />
        </div>
      </AlertOverlayWithConfirmation>
    </>
  );
};
