import {Trans} from '@lingui/macro';
import {ApiKeysListOutput} from '@zentact/api/src/trpc/routers/apiKeysRouter';
import {TableSortValue, TableWrapper} from '@zentact/ui-tailwind';
import {Dispatch, SetStateAction} from 'react';
import {getApiKeysColumns} from './columns';

type Filters = {
  name?: string;
};

type Props<TFilters extends Filters> = {
  apiKeysDataList?: ApiKeysListOutput;
  filters?: TFilters;
  setFilters?: (params: TFilters) => void;
  sort: TableSortValue<string> | null;
  setSort?: Dispatch<SetStateAction<TableSortValue<string>>>;
  pagination?: {pageIndex: number; pageSize: number};
  onPaginationChange?: Dispatch<SetStateAction<{pageIndex: number; pageSize: number}>>;
  refetch: () => void;
  isLoading?: boolean;
};

export const ApiKeysList = <TFilters extends Record<string, unknown>>({
  apiKeysDataList,
  filters,
  setFilters,
  sort,
  setSort,
  pagination,
  onPaginationChange,
  isLoading,
  refetch,
}: Props<TFilters>) => {
  return (
    <TableWrapper
      data={apiKeysDataList?.rows ?? []}
      pageCount={apiKeysDataList?.pagination.pageCount ?? 0}
      columns={getApiKeysColumns(refetch)}
      filterValues={filters}
      onFilterChange={({id, value}) => {
        if (setFilters) {
          setFilters({[id]: value} as TFilters);
        }
      }}
      sortValue={sort}
      onSortChange={setSort}
      noDataMessage={
        <div>
          <h3 className="text-sm font-semibold text-gray-900">
            <Trans>No API Keys</Trans>
          </h3>
        </div>
      }
      pagination={pagination}
      onPaginationChange={onPaginationChange}
      excludeLastColumnFromRowClick
      isLoading={isLoading}
    />
  );
};
