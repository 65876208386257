import {t} from '@lingui/macro';
import {TerminalsItem, TerminalsOutput} from '@zentact/api/src/trpc/routers/terminalRouter';
import {DropDownMinimalMenuIcon} from '@zentact/ui-tailwind';
import {useState} from 'react';
import {ManageAssignPanel} from './manage-assign-panel';

type Props = {
  terminalsRow: TerminalsOutput['rows'][0];
  refetchTerminalsList: () => void;
  openViewTerminalPanel: (row: TerminalsItem | null) => void;
};

const statusToItemsMap = (
  action: (action: 'view' | 'assign') => void
): Record<string, {name: string; onClick: () => void}[]> => ({
  boarded: [
    {name: t`View`, onClick: () => action('view')},
    {name: t`Reassign`, onClick: () => action('assign')},
  ],
  inventory: [
    {name: t`View`, onClick: () => action('view')},
    {name: t`Assign`, onClick: () => action('assign')},
  ],
  deployed: [
    {name: t`View`, onClick: () => action('view')},
    {name: t`Reassign`, onClick: () => action('assign')},
  ],
  reassignmentInProgress: [{name: t`View`, onClick: () => action('view')}],
});

export const ManageActions = ({
  terminalsRow,
  refetchTerminalsList,
  openViewTerminalPanel,
}: Props) => {
  if (!terminalsRow?.status) return null;
  const [assignSlideOutOpen, setAssignSlideOutOpen] = useState(false);

  const handleAction = (action: string) => {
    if (action === 'view') {
      openViewTerminalPanel(terminalsRow);
    }
    if (action === 'assign') {
      setAssignSlideOutOpen(true);
    }
  };

  const menuItems = statusToItemsMap(handleAction)[terminalsRow.status];
  if (!menuItems) return null;

  return (
    <>
      <DropDownMinimalMenuIcon items={menuItems} />
      <ManageAssignPanel
        isOpen={assignSlideOutOpen}
        terminalsRow={terminalsRow}
        refetchTerminalsList={refetchTerminalsList}
        onCancel={() => setAssignSlideOutOpen(false)}
      />
    </>
  );
};
