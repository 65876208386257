import {Trans} from '@lingui/macro';
import {
  SplitConfigurationGroupListOutput,
  TenantDefaultSplitConfigurationGroupOutput,
} from '@zentact/api/src/trpc/routers/splitConfigurationGroupRouter';
import {TableSortValue, TableWrapper} from '@zentact/ui-tailwind';
import {Dispatch, SetStateAction} from 'react';
import {getSplitConfigurationGroupsColumns} from './columns';

type Props<TFilters extends Record<string, unknown>> = {
  splitConfigurationGroupsList?: SplitConfigurationGroupListOutput;
  filters: TFilters;
  setFilters: Dispatch<SetStateAction<TFilters>>;
  sort: TableSortValue<string> | null;
  setSort: Dispatch<SetStateAction<TableSortValue<string> | null>>;
  onRowClick?: (splitConfigurationGroupId: string) => void;
  pagination?: {pageIndex: number; pageSize: number};
  onPaginationChange?: Dispatch<SetStateAction<{pageIndex: number; pageSize: number}>>;
  refetch: () => void;
  defaultSplitConfigurationGroup?: TenantDefaultSplitConfigurationGroupOutput;
  isLoading?: boolean;
};

export const SplitConfigurationGroupsList = <TFilters extends Record<string, unknown>>({
  splitConfigurationGroupsList,
  filters,
  setFilters,
  sort,
  setSort,
  pagination,
  onPaginationChange,
  refetch,
  onRowClick,
  defaultSplitConfigurationGroup,
  isLoading,
}: Props<TFilters>) => {
  return (
    <TableWrapper
      data={splitConfigurationGroupsList?.rows ?? []}
      pageCount={splitConfigurationGroupsList?.pagination.pageCount ?? 0}
      columns={getSplitConfigurationGroupsColumns({
        refetchSplitConfigurationGroupsList: refetch,
        openDetails: onRowClick,
        defaultSplitConfigurationGroup,
      })}
      filterValues={filters}
      onFilterChange={({id, value}) => {
        setFilters(prev => ({...prev, [id]: value}));
      }}
      sortValue={sort}
      onSortChange={setSort}
      noDataMessage={
        <div>
          <h3 className="text-sm font-semibold text-gray-900">
            <Trans>No Fee Groups</Trans>
          </h3>
        </div>
      }
      pagination={pagination}
      onPaginationChange={onPaginationChange}
      onRowClick={item => item && onRowClick?.(item.id)}
      excludeLastColumnFromRowClick
      isLoading={isLoading}
    />
  );
};
