import {ArrowDownTrayIcon} from '@heroicons/react/24/outline';
import {useLingui} from '@lingui/react';
import {createTRPCReact} from '@trpc/react-query';
import type {ServerRouter} from '@zentact/api';
import {useNotification} from '@zentact/ui-tailwind';
import {IconSpinner} from '@zentact/ui-tailwind/icons/spinner';
import {useCallback, useState} from 'react';

type Props = {
  registrationSessionId: string;
  trpc: ReturnType<typeof createTRPCReact<ServerRouter>>;
};
export const MerchantApplicationFileDownload = ({registrationSessionId, trpc}: Props) => {
  const {showErrorNotification} = useNotification();
  const [isFileLoading, setIsFileLoading] = useState(false);
  const {i18n} = useLingui();

  const trpcContext = trpc.useUtils();

  const downloadFile = async (url: string, filename: string) => {
    const response = await fetch(url, {
      headers: {
        'Content-Type': 'application/octet-stream',
      },
    });
    const blob = await response.blob();
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    link.click();
  };
  const handleDocumentDownload = useCallback(async () => {
    setIsFileLoading(true);
    try {
      const {signedUrl, fileName} =
        await trpcContext.merchantRegistration.generateApplicationDocumentDownloadLink.fetch({
          registrationSessionId,
        });
      await downloadFile(signedUrl, fileName);
    } catch (e) {
      showErrorNotification(i18n._('Failed to download a file'), (e as Error).message);
    }
    setIsFileLoading(false);
  }, [registrationSessionId, setIsFileLoading, showErrorNotification]);

  return (
    <div className="flex items-center justify-end">
      {!isFileLoading ? (
        <ArrowDownTrayIcon
          className="w-5 h-5 cursor-pointer text-primary-600"
          onClick={handleDocumentDownload}
        />
      ) : (
        <IconSpinner />
      )}
    </div>
  );
};
