import {Dialog, Transition} from '@headlessui/react';
import {ExclamationCircleIcon} from '@heroicons/react/24/outline';
import {zodResolver} from '@hookform/resolvers/zod';
import {I18n} from '@lingui/core';
import {createTRPCReact} from '@trpc/react-query';
import type {ServerRouter} from '@zentact/api';
import {
  CurrencyCode,
  LocaleCode,
  MerchantAccountPublicStatus,
  formatAmount,
  fromMinorUnits,
  toMinorUnits,
} from '@zentact/common';
import {
  AlertOverlayWithConfirmation,
  InputCheckbox,
  Label,
  MerchantAccountsPicker,
  ValidationError,
  useNotification,
} from '@zentact/ui-tailwind';
import {Fragment, useCallback, useRef, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {z} from 'zod';
import {Button, InputText} from '../forms';

const getInitiateTransferFormSchema = (i18n: I18n, currency: CurrencyCode) =>
  z.object({
    merchantAccountId: z.string(),
    amount: z
      .number({
        // biome-ignore lint/style/useNamingConvention: Zod convention
        invalid_type_error: i18n._('amount must be a number'),
        // biome-ignore lint/style/useNamingConvention: Zod convention
        required_error: i18n._('amount is required'),
      })
      .min(
        fromMinorUnits(100, currency),
        i18n._('Amount must be greater than or equal to {amount}', {
          amount: formatAmount(100, i18n.locale as LocaleCode, currency),
        })
      )
      .max(
        fromMinorUnits(10000000, currency),
        i18n._('Amount must be lower than or equal to {amount}', {
          amount: formatAmount(10000000, i18n.locale as LocaleCode, currency),
        })
      ),
    receiverMerchantAccountId: z.string(),
    isAuthorized: z.boolean().refine(val => val === true, {
      message: i18n._('Please confirm that you are authorized'),
    }),
    description: z
      .string()
      .min(1, i18n._('The transfer reason is required'))
      .max(140, i18n._('The transfer reason cannot be longer than 140 characters')),
  });

type InitiateTransferFormData = z.infer<ReturnType<typeof getInitiateTransferFormSchema>>;

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  merchantAccount: {
    id: string;
    businessName: string;
    organizationId: string | null;
    status: MerchantAccountPublicStatus;
  };
  tenantName: string;
  currentBalance: number;
  currency: CurrencyCode;
  i18n: I18n;
  onSuccess: () => void;
  trpc: ReturnType<typeof createTRPCReact<ServerRouter>>;
};

export const InitiateTransferOverlay = ({
  open,
  setOpen,
  i18n,
  merchantAccount,
  currency,
  trpc,
  currentBalance,
  onSuccess,
  tenantName,
}: Props) => {
  const cancelButtonRef = useRef(null);

  const {
    handleSubmit,
    control,
    formState: {errors},
    watch,
    register,
    trigger,
    reset,
  } = useForm<InitiateTransferFormData>({
    resolver: zodResolver(getInitiateTransferFormSchema(i18n, currency)),
    defaultValues: {
      amount: fromMinorUnits(currentBalance, currency),
      merchantAccountId: merchantAccount.id,
    },
  });
  const [isSubmitting, setSubmitting] = useState(false);
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  const {showSuccessNotification, showErrorNotification} = useNotification();
  const liableAccount = {
    id: '',
    businessName: `${tenantName} Liable Account`,
    status: 'ACTIVE' as const,
  };

  const amount = watch('amount');
  const isScheduledTransfer = toMinorUnits(amount, currency) > currentBalance;

  const initiateTransfer = trpc.internalTransfer.initiateTransferFromMerchantAccount.useMutation({
    onSuccess: () => {
      showSuccessNotification(
        i18n._('Transfer initiated!'),
        i18n._(
          'It may take some time to reflect on your account. Please, check account activity under the finance section for transfer confirmation.'
        )
      );
      onSuccess();
      setOpen(false);
      setConfirmationOpen(false);
      setSubmitting(false);
    },
    onError: () => {
      showErrorNotification(i18n._('Failed to initiate a transfer'));
      setSubmitting(false);
    },
  });

  const handleTransferSend = (data: InitiateTransferFormData) => {
    setSubmitting(true);
    initiateTransfer.mutate({
      currency,
      merchantAccountId: data.merchantAccountId,
      amount: toMinorUnits(data.amount, currency),
      description: data.description,
    });
  };

  const onContinue = useCallback(async () => {
    const isValid = await trigger();
    if (isValid) {
      setConfirmationOpen(true);
    }
  }, [trigger, setConfirmationOpen]);

  const handleCloseDialog = () => {
    setOpen(false);
    reset();
  };
  const getConfirmationWindowDescription = () => {
    const data = {
      amount: formatAmount(toMinorUnits(amount, currency), i18n.locale as LocaleCode, currency),
      sender: merchantAccount.businessName,
      receiver: liableAccount.businessName,
      currentBalance: formatAmount(currentBalance, i18n.locale as LocaleCode, currency),
    };
    return isScheduledTransfer
      ? i18n._(
          'You are about to schedule a transfer for {amount} from {sender} to {receiver}. Are you sure you want to continue?',
          data
        )
      : i18n._(
          'You are about to transfer {amount} from {sender} ({currentBalance}) to {receiver}. Are you sure you want to continue?',
          data
        );
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-40"
        initialFocus={cancelButtonRef}
        onClose={handleCloseDialog}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 z-40 transition-opacity bg-gray-500 bg-opacity-75" />
        </Transition.Child>
        <div className="fixed inset-0 z-40">
          <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-lg">
                <form
                  onSubmit={handleSubmit(handleTransferSend)}
                  className="mt-4 shadow-sm before:bg-white md:col-span-2"
                >
                  <div className="px-4 pt-4 pb-4 bg-white sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto rounded-full bg-primary-100 sm:mx-0 sm:h-10 sm:w-10">
                        <ExclamationCircleIcon
                          className="w-6 h-6 text-primary-600"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="mt-3 sm:ml-4 sm:mt-0">
                        <div className="text-center sm:text-left">
                          <Dialog.Title
                            as="h3"
                            className="text-base font-semibold leading-6 text-gray-900"
                          >
                            {i18n._('Initiate {currency} Transfer', {currency})}
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="text-sm text-gray-500">
                              {i18n._(
                                'How much would you like to transfer from {businessName}? If you enter an amount greater than the available balance, the transfer will be attempted before the next scheduled payout. You can cancel a scheduled transfer at any time before it completes. Please ensure you are authorized to make this transfer.',
                                {
                                  businessName: merchantAccount.businessName,
                                }
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="flex flex-col mt-5 mb-2 gap-y-4">
                          <div>
                            <Label text={i18n._('Transfer From')}>
                              <Controller
                                control={control}
                                name="merchantAccountId"
                                render={({field}) => (
                                  <MerchantAccountsPicker
                                    selectedMerchantAccount={field.value}
                                    onSelectMerchantAccount={field.onChange}
                                    merchantAccountsOptions={[merchantAccount]}
                                  />
                                )}
                              />
                              <ValidationError isVisible={Boolean(errors.merchantAccountId)}>
                                {errors.merchantAccountId?.message}
                              </ValidationError>
                            </Label>
                          </div>
                          <div>
                            <Label
                              text={i18n._(
                                'Transfer Amount ({currentBalance} {currency} available)',
                                {
                                  currentBalance: formatAmount(
                                    currentBalance,
                                    i18n.locale as LocaleCode,
                                    currency
                                  ),
                                  currency,
                                }
                              )}
                            >
                              <InputText
                                {...register('amount', {
                                  valueAsNumber: true,
                                })}
                                addonBefore="$"
                                hasError={Boolean(errors.amount)}
                              />
                              <ValidationError isVisible={Boolean(errors.amount)}>
                                {errors.amount?.message}
                              </ValidationError>
                            </Label>
                          </div>
                          <div>
                            <Label text={i18n._('Transfer To')}>
                              <Controller
                                control={control}
                                name="receiverMerchantAccountId"
                                render={({field}) => (
                                  <MerchantAccountsPicker
                                    selectedMerchantAccount={field.value}
                                    onSelectMerchantAccount={field.onChange}
                                    merchantAccountsOptions={[liableAccount]}
                                  />
                                )}
                              />
                              <ValidationError isVisible={Boolean(errors.merchantAccountId)}>
                                {errors.merchantAccountId?.message}
                              </ValidationError>
                            </Label>
                          </div>
                          <Label text={i18n._('Reason')}>
                            <InputText {...register('description')} />
                            <ValidationError isVisible={Boolean(errors.description)}>
                              {errors.description?.message}
                            </ValidationError>
                          </Label>
                          <div>
                            <InputCheckbox {...register('isAuthorized')}>
                              {i18n._('I am authorized to make this transfer')}
                            </InputCheckbox>
                            <ValidationError isVisible={Boolean(errors.isAuthorized)}>
                              {errors.isAuthorized?.message}
                            </ValidationError>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <Button
                      type="button"
                      className="inline-flex justify-center w-full px-3 py-2 text-sm font-semibold text-white rounded-md shadow-sm sm:ml-3 sm:w-auto bg-primary-600 hover:bg-primary-500"
                      onClick={onContinue}
                    >
                      {i18n._('Continue')}
                    </Button>
                    <button
                      type="button"
                      className="inline-flex justify-center w-full px-3 py-2 mt-3 text-sm font-semibold text-gray-900 bg-white rounded-md shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={handleCloseDialog}
                      ref={cancelButtonRef}
                    >
                      {i18n._('Cancel')}
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
        {isConfirmationOpen && (
          <AlertOverlayWithConfirmation
            open={isConfirmationOpen}
            setOpen={setConfirmationOpen}
            handleAction={() => handleSubmit(handleTransferSend)()}
            loading={isSubmitting}
            mode="error"
            buttonMode="success"
            localeText={{
              title: i18n._('Confirm Transfer'),
              description: isConfirmationOpen ? getConfirmationWindowDescription() : '',
              confirm: isScheduledTransfer ? i18n._('Schedule Transfer') : i18n._('Transfer Now'),
              cancel: i18n._('Cancel Transfer'),
            }}
          />
        )}
      </Dialog>
    </Transition.Root>
  );
};
