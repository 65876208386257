import {I18n} from '@lingui/core';
import {MerchantAccountPublicStatus} from '@zentact/common';
import {OrganizationStatus} from '@zentact/db';
import {useCallback} from 'react';
import {getLocalizedOrganizationStatusMap} from '../localizedMaps/organizationStatusDisplayMap';
import {ResetTableFiltersButton} from '../table';
import {CheckboxFilter} from './CheckboxFilter';
import {FlatFillColors, FlatPillWithDot} from './FlatPillWithDot';
import {InputSearchFilter} from './InputSearchFilter';

export const organizationStatusToColor: {
  [_ in OrganizationStatus]: FlatFillColors;
} = {
  // biome-ignore lint/style/useNamingConvention: DB Mapping convention
  ACTIVE: 'green',
  // biome-ignore lint/style/useNamingConvention: DB Mapping convention
  DEACTIVATED: 'gray',
};

type Props<
  T extends {
    name?: string | undefined;
    status?: string[] | MerchantAccountPublicStatus[] | undefined;
  },
> = {
  defaultFilters: T;
  typedSearchParams: T;
  setTypedSearchParams: (params: T) => void;
  name?: string;
  status?: string[];
  i18n: I18n;
};

export const OrganizationFilters = <
  T extends {
    name?: string | undefined;
    status?: string[] | MerchantAccountPublicStatus[] | undefined;
  },
>({
  defaultFilters,
  typedSearchParams,
  setTypedSearchParams,
  status,
  name,
  i18n,
}: Props<T>) => {
  const statusLabelMap = getLocalizedOrganizationStatusMap(i18n);

  const handleFilterChange = useCallback(
    <K extends keyof T>(key: K, value: T[K]) => {
      setTypedSearchParams({[key]: value} as unknown as T);
    },
    [setTypedSearchParams]
  );

  const handleFilterChangeWithClear = useCallback(
    <K extends keyof T>(key: K, value: T[K]) => {
      setTypedSearchParams({...defaultFilters, [key]: value} as unknown as T);
    },
    [setTypedSearchParams]
  );
  return (
    <div className="flex flex-wrap items-center justify-end gap-2 sm:flex-nowrap">
      <div className="max-sm:w-full">
        <ResetTableFiltersButton
          defaultFilters={{}}
          activeFilters={typedSearchParams}
          setFilters={setTypedSearchParams}
        />
      </div>
      <div className="max-sm:w-full">
        <InputSearchFilter
          label="Organization"
          selected={name}
          onChange={value => handleFilterChangeWithClear('name', value)}
        />
      </div>
      <div className="max-sm:w-full">
        <CheckboxFilter
          label="Status"
          contentClass="right-0"
          selected={status}
          onChange={value => handleFilterChange('status', value)}
          options={(
            Object.keys(organizationStatusToColor) as Array<keyof typeof organizationStatusToColor>
          ).map(status => ({
            element: (
              <FlatPillWithDot
                color={organizationStatusToColor[status] || 'blue'}
                label={statusLabelMap[status] || status}
              />
            ),
            key: status,
          }))}
        />
      </div>
    </div>
  );
};
