import {RoutePath} from '@/components/layout/navigation';
import {useStore} from '@/store';
import {Trans, t} from '@lingui/macro';
import {OtherRevenueOutputItem} from '@zentact/api/src/trpc/routers/reportingRouter';
import {formatAmount, formatLocaleDate} from '@zentact/common';
import {SlideOverWithBrandedHeader} from '@zentact/ui-tailwind';
import {Link, generatePath} from 'react-router-dom';
import {displayOtherRevenueTypeMap} from './columns';

export type OtherRevenueViewPanelProps = {
  isOpen: boolean;
  onCancel: () => void;
  row: OtherRevenueOutputItem | null;
};

export const OtherRevenueViewPanel = ({isOpen, onCancel, row}: OtherRevenueViewPanelProps) => {
  const {locale} = useStore();

  if (!row) {
    return null;
  }

  const {
    type,
    createdAt,
    organizationName,
    businessName,
    amount,
    currency,
    originalPaymentPspReferenceId,
    description,
  } = row;

  return (
    <SlideOverWithBrandedHeader
      isOpen={isOpen}
      closeHandler={onCancel}
      title={t`View Merchant Details`}
    >
      <>
        <div className="border-gray-100">
          <dl className="divide-y divide-gray-100">
            <div className="px-4 pb-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                <Trans>Merchant Account</Trans>
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 break-words sm:col-span-2 sm:mt-0">
                {businessName}
              </dd>
            </div>
            <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                <Trans>Organization</Trans>
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 break-words sm:col-span-2 sm:mt-0">
                {organizationName}
              </dd>
            </div>
            <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                <Trans>Fee Type</Trans>
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 break-words sm:col-span-2 sm:mt-0">
                {displayOtherRevenueTypeMap(type)}
              </dd>
            </div>
            <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                <Trans>Amount</Trans>
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 break-words sm:col-span-2 sm:mt-0">
                {formatAmount(amount, locale, currency)}
              </dd>
            </div>
            {!!originalPaymentPspReferenceId && (
              <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  <Trans>Payment PSP Reference</Trans>
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 break-words sm:col-span-2 sm:mt-0">
                  <Link
                    to={generatePath(RoutePath.PAYMENT_DETAILS, {
                      pspReferenceId: originalPaymentPspReferenceId,
                    })}
                    className="text-primary-600 hover:underline"
                  >
                    {originalPaymentPspReferenceId}
                  </Link>
                </dd>
              </div>
            )}
            <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                <Trans>Desciption</Trans>
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 break-words sm:col-span-2 sm:mt-0">
                {description}
              </dd>
            </div>
            <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                <Trans>Date</Trans>
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 break-words sm:col-span-2 sm:mt-0">
                {formatLocaleDate(createdAt)}
              </dd>
            </div>
          </dl>
        </div>
      </>
    </SlideOverWithBrandedHeader>
  );
};
