import {Trans} from '@lingui/macro';
import {TerminalsItem, TerminalsOutput} from '@zentact/api/src/trpc/routers/terminalRouter';
import {TableWrapper} from '@zentact/ui-tailwind';
import {Dispatch, SetStateAction} from 'react';
import {getTerminalsManageColumns} from './columns';

type Props = {
  refetch: () => void;
  terminalsList?: TerminalsOutput;
  pagination: {pageIndex: number; pageSize: number};
  onPaginationChange: Dispatch<SetStateAction<{pageIndex: number; pageSize: number}>>;
  openViewTerminalPanel: (row: TerminalsItem | null) => void;
  isLoading?: boolean;
};

export const TerminalsManageTable = ({
  refetch,
  pagination,
  terminalsList,
  onPaginationChange,
  openViewTerminalPanel,
  isLoading,
}: Props) => {
  return (
    <TableWrapper
      data={terminalsList?.rows ?? []}
      pageCount={terminalsList?.pagination?.pageCount ?? 0}
      columns={getTerminalsManageColumns({refetchTerminalsList: refetch, openViewTerminalPanel})}
      noDataMessage={
        <div>
          <h3 className="text-sm font-semibold text-gray-900">
            <Trans>No Terminals Recorded</Trans>
          </h3>
        </div>
      }
      isLoading={isLoading}
      pagination={pagination}
      onPaginationChange={onPaginationChange}
      onRowClick={openViewTerminalPanel}
      excludeLastColumnFromRowClick
    />
  );
};
