import {trpc} from '@/api/trpcClient';
import {RoutePath} from '@/components/layout/navigation';
import {MakeDefaultConfirmation} from '@/pages/configure/fee-groups/split-configuration-groups-list/make-default-confirmation/make-default-confirmation';
import {Trans, t} from '@lingui/macro';
import {
  SplitConfigurationGroupListItemOutput,
  TenantDefaultSplitConfigurationGroupOutput,
} from '@zentact/api/src/trpc/routers/splitConfigurationGroupRouter';
import {
  ErrorCode,
  SplitConfigurationGroupPublicStatus,
  isFormattedTrpcError,
} from '@zentact/common';
import {
  AlertOverlayWithConfirmation,
  DropDownMinimalMenuIcon,
  useNotification,
} from '@zentact/ui-tailwind';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';

type SplitConfigurationGroupData = Pick<
  SplitConfigurationGroupListItemOutput,
  'id' | 'name' | 'isDefault' | 'status'
>;

type Props = {
  splitConfigurationGroup: SplitConfigurationGroupData;
  refetchData: () => void;
  openDetails?: (splitConfigurationGroupId: string) => void;
  defaultSplitConfigurationGroup?: TenantDefaultSplitConfigurationGroupOutput;
  view?: 'button' | 'icon';
};

enum SplitConfigurationGroupAction {
  VIEW_DETAILS = 'details',
  MAKE_DEFAULT = 'makeDefault',
  MAKE_INACTIVE = 'makeInactive',
  MAKE_ACTIVE = 'makeActive',
  VIEW_ASSIGNED_MERCHANTS = 'viewAssignedMerchants',
  DUPLICATE = 'duplicate',
}

const statusToItemsMap = (
  action: (action: SplitConfigurationGroupAction) => void,
  {
    openDetails,
    isDefaultSplitConfigurationGroup,
  }: {
    openDetails?: (splitConfigurationGroupId: string) => void;
    isDefaultSplitConfigurationGroup: boolean;
  }
): Record<
  SplitConfigurationGroupPublicStatus,
  {name: string; onClick: () => void; itemClassName?: string}[]
> => {
  const detailsAction = openDetails
    ? [
        {
          name: t`Details`,
          onClick: () => action(SplitConfigurationGroupAction.VIEW_DETAILS),
        },
      ]
    : [];
  const viewAssignedMerchantsAction = {
    name: t`View Assigned Merchants`,
    onClick: () => action(SplitConfigurationGroupAction.VIEW_ASSIGNED_MERCHANTS),
  };
  const duplicateAction = {
    name: t`Duplicate`,
    onClick: () => action(SplitConfigurationGroupAction.DUPLICATE),
  };

  return {
    [SplitConfigurationGroupPublicStatus.ACTIVE]: [
      ...detailsAction,
      viewAssignedMerchantsAction,
      duplicateAction,
      ...(!isDefaultSplitConfigurationGroup
        ? [
            {
              name: t`Make Default`,
              onClick: () => action(SplitConfigurationGroupAction.MAKE_DEFAULT),
            },
            {
              name: t`Make Inactive`,
              onClick: () => action(SplitConfigurationGroupAction.MAKE_INACTIVE),
              itemClassName: 'text-red-600',
            },
          ]
        : []),
    ],
    [SplitConfigurationGroupPublicStatus.INACTIVE]: [
      ...detailsAction,
      viewAssignedMerchantsAction,
      duplicateAction,
      {
        name: t`Make Active`,
        onClick: () => action(SplitConfigurationGroupAction.MAKE_ACTIVE),
      },
    ],
    [SplitConfigurationGroupPublicStatus.ARCHIVED]: [...detailsAction, duplicateAction],
  };
};

export const SplitConfigurationGroupActions = ({
  splitConfigurationGroup,
  refetchData,
  openDetails,
  defaultSplitConfigurationGroup,
  view = 'icon',
}: Props) => {
  const navigate = useNavigate();
  const {showSuccessNotification, showErrorNotification} = useNotification();
  const [makeActiveConfirmationOpen, setMakeActiveConfirmationOpen] = useState(false);
  const [makeInactiveConfirmationOpen, setMakeInactiveConfirmationOpen] = useState(false);
  const [makeDefaultConfirmationOpen, setMakeDefaultConfirmationOpen] = useState(false);
  const updateSplitConfigurationGroupMutation = trpc.splitConfigurationGroup.updateById.useMutation(
    {
      onSuccess: () => {
        refetchData();
        setMakeActiveConfirmationOpen(false);
        setMakeInactiveConfirmationOpen(false);
        setMakeDefaultConfirmationOpen(false);
      },
      onError: error => {
        if (isFormattedTrpcError(error)) {
          if (error.data.errorCode === ErrorCode.SPLIT_CONFIGURATION_GROUP_IS_DEFAULT) {
            showErrorNotification(t`Error`, t`Default fee group cannot be set to inactive.`);
            return;
          }

          if (error.data.errorCode === ErrorCode.SPLIT_CONFIGURATION_GROUP_IS_INACTIVE) {
            showErrorNotification(t`Error`, t`Fee group must be active to mark as default.`);
            return;
          }
        }
        showErrorNotification(t`Error`, error.message);
      },
    }
  );

  const handleAction = (action: string) => {
    if (action === SplitConfigurationGroupAction.VIEW_DETAILS && openDetails) {
      openDetails(splitConfigurationGroup.id);
      return;
    }
    if (action === SplitConfigurationGroupAction.MAKE_DEFAULT) {
      setMakeDefaultConfirmationOpen(true);
      return;
    }

    if (action === SplitConfigurationGroupAction.MAKE_INACTIVE) {
      setMakeInactiveConfirmationOpen(true);
      return;
    }

    if (action === SplitConfigurationGroupAction.MAKE_ACTIVE) {
      setMakeActiveConfirmationOpen(true);
      return;
    }

    if (action === SplitConfigurationGroupAction.VIEW_ASSIGNED_MERCHANTS) {
      navigate(`${RoutePath.CUSTOMERS_MERCHANTS}?feeGroupId=${splitConfigurationGroup.id}`);
      return;
    }

    if (action === SplitConfigurationGroupAction.DUPLICATE) {
      navigate(`${RoutePath.FEE_GROUP_CREATE}?sourceFeeGroupId=${splitConfigurationGroup.id}`);
      return;
    }
  };

  const handleMakeActive = () => {
    updateSplitConfigurationGroupMutation.mutate(
      {
        splitConfigurationGroupId: splitConfigurationGroup.id,
        data: {status: SplitConfigurationGroupPublicStatus.ACTIVE},
      },
      {
        onSuccess: () => {
          showSuccessNotification(
            t`Fee group "${splitConfigurationGroup.name}" has been activated.`
          );
        },
      }
    );
  };

  const handleMakeInactive = () => {
    updateSplitConfigurationGroupMutation.mutate(
      {
        splitConfigurationGroupId: splitConfigurationGroup.id,
        data: {status: SplitConfigurationGroupPublicStatus.INACTIVE},
      },
      {
        onSuccess: () => {
          showSuccessNotification(
            t`Fee group "${splitConfigurationGroup.name}" has been deactivated.`
          );
        },
      }
    );
  };

  const handleMakeDefault = () => {
    updateSplitConfigurationGroupMutation.mutate(
      {
        splitConfigurationGroupId: splitConfigurationGroup.id,
        data: {isDefault: true},
      },
      {
        onSuccess: () => {
          showSuccessNotification(
            t`Fee group "${splitConfigurationGroup.name}" has been set as the default fee group for new merchants.`
          );
        },
      }
    );
  };

  const menuItems = statusToItemsMap(handleAction, {
    openDetails,
    isDefaultSplitConfigurationGroup: splitConfigurationGroup.isDefault,
  })[splitConfigurationGroup.status];

  if (!menuItems) {
    return null;
  }

  return (
    <>
      <DropDownMinimalMenuIcon
        items={menuItems}
        buttonContent={view === 'button' ? <Trans>Actions</Trans> : undefined}
      />
      <AlertOverlayWithConfirmation
        open={makeActiveConfirmationOpen}
        setOpen={setMakeActiveConfirmationOpen}
        handleAction={handleMakeActive}
        localeText={{
          title: t`Activate Fee Group`,
          description: t`Are you sure you want to activate the fee group "${splitConfigurationGroup.name}"?`,
          confirm: t`Activate`,
          cancel: t`Cancel`,
        }}
        mode="success"
        loading={updateSplitConfigurationGroupMutation.isLoading}
      />
      <AlertOverlayWithConfirmation
        open={makeInactiveConfirmationOpen}
        setOpen={setMakeInactiveConfirmationOpen}
        handleAction={handleMakeInactive}
        localeText={{
          title: t`Deactivate Fee Group`,
          description: (
            <span>
              <Trans>
                Are you sure you want to deactivate the fee group{' '}
                <strong>{splitConfigurationGroup.name}</strong>?
              </Trans>
              <span className="flex mt-2">
                <Trans>
                  This fee group still applies to assigned merchants, but new merchants cannot use
                  this fee group.
                </Trans>
              </span>
            </span>
          ),
          confirm: t`Deactivate`,
          cancel: t`Cancel`,
        }}
        loading={updateSplitConfigurationGroupMutation.isLoading}
      />
      <MakeDefaultConfirmation
        open={makeDefaultConfirmationOpen}
        setOpen={setMakeDefaultConfirmationOpen}
        handleMakeDefault={handleMakeDefault}
        isLoading={updateSplitConfigurationGroupMutation.isLoading}
        defaultSplitConfigurationGroup={defaultSplitConfigurationGroup}
        splitConfigurationGroupName={splitConfigurationGroup.name}
      />
    </>
  );
};
