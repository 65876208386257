import {useEffect, useState} from 'react';

export const useDeveloperPhrase = (phrase: string[]): [boolean, () => void] => {
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    let count = 0;

    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.code !== phrase[count]) {
        count = 0;
        return;
      }

      count++;
      if (count === phrase.length) {
        setSuccess(true);
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => document.removeEventListener('keydown', keyDownHandler);
  }, []);

  const reset = () => setSuccess(false);

  return [success, reset];
};
