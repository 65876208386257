import {Trans} from '@lingui/macro';
import {createColumnHelper} from '@tanstack/react-table';
import {ApiKeysListItemOutput} from '@zentact/api/src/trpc/routers/apiKeysRouter';
import {TableColumnSize, formatLocaleDate} from '@zentact/common';
import {
  FlatPillWithDot,
  HighlightedText,
  TableSearchFilter,
  Tooltip,
  getTableMeta,
  truncateText,
} from '@zentact/ui-tailwind';
import {ApiKeysActions} from './api-keys-actions';

const columnsHelper = createColumnHelper<ApiKeysListItemOutput>();

export const getApiKeysColumns = (refetch: () => void) => {
  return [
    columnsHelper.display({
      cell: props => {
        const {filterValues} = getTableMeta(props.table);
        const searchString = filterValues?.name as string;
        const textContent = props.row.original.name || '-';

        return textContent.length > 20 ? (
          <Tooltip text={textContent}>
            <HighlightedText text={truncateText(textContent)} highlight={searchString} />
          </Tooltip>
        ) : (
          <HighlightedText text={textContent} highlight={searchString} />
        );
      },
      id: 'name',
      header: () => <Trans>Name</Trans>,
      meta: {
        filter: {
          filterId: 'name',
          renderFilter: ({...filterProps}) => (
            <TableSearchFilter
              {...filterProps}
              inputClassName="absolute -mt-4 w-40 -translate-x-14 transform"
            />
          ),
        },
        sort: {
          isSortable: true,
        },
      },
      size: TableColumnSize.S,
    }),
    columnsHelper.accessor('apiKeyPrefix', {
      id: 'apiKeyPrefix',
      header: () => <Trans>API Key Prefix</Trans>,
      meta: {
        visibleAt: 'lg',
      },
      size: TableColumnSize.S,
    }),
    columnsHelper.accessor('enabled', {
      cell: info => (
        <FlatPillWithDot
          color={info.getValue() ? 'green' : 'red'}
          label={info.getValue() ? 'Active' : 'Inactive'}
        />
      ),
      meta: {
        collapseAt: 'sm',
      },
      header: () => <Trans>Status</Trans>,
      size: TableColumnSize.M,
    }),
    columnsHelper.accessor('createdByUserName', {
      id: 'createdByUserName',
      header: () => <Trans>Created By</Trans>,
      meta: {
        visibleAt: 'md',
      },
      size: TableColumnSize.S,
    }),
    columnsHelper.accessor('createdAt', {
      cell: info => formatLocaleDate(info.getValue(), 'shortWithTime'),
      header: () => <Trans>Created At</Trans>,
      meta: {
        sort: {
          isSortable: true,
        },
      },
      size: TableColumnSize.S,
    }),
    columnsHelper.display({
      id: 'actions',
      cell: props => (
        <div className="flex justify-center">
          <ApiKeysActions row={props.row.original} refetch={refetch} />
        </div>
      ),
      size: TableColumnSize.ACTIONS,
    }),
  ];
};
