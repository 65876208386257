import {useStore} from '@/store';
import {Trans} from '@lingui/macro';
import {OrganizationsRevenueListOutput} from '@zentact/api/src/trpc/routers/paymentRouter';
import {TableSortValue, TableWrapper} from '@zentact/ui-tailwind';
import {Dispatch, SetStateAction} from 'react';
import {getOrganizationColumns} from './columns';

type Props = {
  data?: OrganizationsRevenueListOutput;
  sort: TableSortValue<string> | null;
  setSort: Dispatch<TableSortValue<string> | null>;
  isLoading?: boolean;
  pagination?: {pageIndex: number; pageSize: number};
  onPaginationChange?: Dispatch<SetStateAction<{pageIndex: number; pageSize: number}>>;
  allowSorts?: boolean;
};

export const OrganizationsRevenueList = ({
  data,
  isLoading,
  pagination,
  sort,
  setSort,
  onPaginationChange,
  allowSorts,
}: Props) => {
  const {locale} = useStore();
  return (
    <TableWrapper
      data={data?.rows ?? []}
      columns={getOrganizationColumns({allowSorts, locale})}
      noDataMessage={
        <div>
          <h3 className="text-sm font-semibold text-gray-900">
            <Trans>No organizations</Trans>
          </h3>
          <span className="text-sm text-gray-500">
            <Trans>As organizations register, they will appear here.</Trans>
          </span>
        </div>
      }
      isLoading={isLoading}
      pagination={pagination}
      onPaginationChange={onPaginationChange}
      pageCount={data?.pagination.pageCount ?? 0}
      sortValue={sort}
      onSortChange={setSort}
    />
  );
};
