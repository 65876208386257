import {FunnelIcon} from '@heroicons/react/24/outline';
import {FunnelIcon as SolidFunelIcon} from '@heroicons/react/24/solid';
import {t} from '@lingui/macro';
import {OrganizationSummaryOutput} from '@zentact/api/src/trpc/routers/organizationRouter';
import {MerchantAccountPublicStatus} from '@zentact/common';
import {SkeletonLoader} from '@zentact/ui-tailwind';

type Props = {
  organizationsSummary?: OrganizationSummaryOutput;
  isLoading: boolean;
  handleSelectStatus: (status: MerchantAccountPublicStatus[]) => void;
  status: MerchantAccountPublicStatus[];
};

export const MerchantsStats = ({
  organizationsSummary,
  isLoading,
  handleSelectStatus,
  status,
}: Props) => {
  const stats = [
    {
      name: `${t`Total Active`}`,
      stat: organizationsSummary?.activeCount || 0,
      status: [MerchantAccountPublicStatus.ACTIVE],
    },
    {
      name: t`Total Onboarding`,
      stat: organizationsSummary?.onboardingCount || 0,
      status: [
        MerchantAccountPublicStatus.ONBOARDING,
        MerchantAccountPublicStatus.AWAITING_INFO,
        MerchantAccountPublicStatus.AWAITING_REVIEW,
      ],
    },
    {
      name: t`Total Onboarding Errors`,
      stat: organizationsSummary?.rejectedCount || 0,
      status: [MerchantAccountPublicStatus.REJECTED],
    },
  ];

  return (
    <div>
      <dl className="grid grid-cols-1 gap-5 sm:grid-cols-3">
        {stats.map(item => (
          <SkeletonLoader
            key={item.name}
            isLoading={isLoading}
            className="h-3 min-h-[300px] max-w-xs ring-gray-400/5 sm:rounded-xl md:col-span-2 md:min-h-full"
          >
            {/* biome-ignore lint/a11y/useKeyWithClickEvents: TODO */}
            <div
              key={item.name}
              className="mb-5 flex items-start justify-between overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6 cursor-pointer"
              onClick={() => handleSelectStatus(item.status)}
            >
              <div>
                <dt className="truncate text-sm font-medium text-gray-500">{item.name}</dt>
                <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                  {item.stat}
                </dd>
              </div>
              {status.length === item.status.length &&
              item.status.every(element => status.includes(element)) ? (
                <SolidFunelIcon className="text-primary-600 h-5 w-5" />
              ) : (
                <FunnelIcon className="h-5 w-5 text-gray-500" />
              )}
            </div>
          </SkeletonLoader>
        ))}
      </dl>
    </div>
  );
};
