import {Trans, t} from '@lingui/macro';
import {TenantDefaultSplitConfigurationGroupOutput} from '@zentact/api/src/trpc/routers/splitConfigurationGroupRouter';
import {AlertOverlayWithConfirmation} from '@zentact/ui-tailwind';

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleMakeDefault: () => void;
  isLoading: boolean;
  splitConfigurationGroupName: string;
  defaultSplitConfigurationGroup?: TenantDefaultSplitConfigurationGroupOutput;
};

export const MakeDefaultConfirmation = ({
  open,
  setOpen,
  handleMakeDefault,
  isLoading,
  splitConfigurationGroupName,
  defaultSplitConfigurationGroup,
}: Props) => {
  return (
    <AlertOverlayWithConfirmation
      open={open}
      setOpen={setOpen}
      handleAction={handleMakeDefault}
      localeText={{
        title: t`Set Default Fee Group`,
        description: (
          <span>
            <Trans>
              Are you sure you want to set <strong>{splitConfigurationGroupName}</strong> as the
              default fee group for new merchants?
            </Trans>
            {defaultSplitConfigurationGroup && (
              <span className="flex mt-2">
                <Trans>
                  Current Default Fee Group:{' '}
                  <strong className="pl-1">{defaultSplitConfigurationGroup.name}</strong>
                </Trans>
              </span>
            )}
          </span>
        ),
        confirm: t`Set as Default`,
        cancel: t`Cancel`,
      }}
      mode="success"
      loading={isLoading}
    />
  );
};
