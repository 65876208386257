import {I18n} from '@lingui/core';
import {t} from '@lingui/macro';
import {InterchangePaymentsListOutput} from '@zentact/api/src/trpc/routers/paymentRouter';
import {
  CurrencyCode,
  LocaleCode,
  formatAmountWithoutCurrency,
  formatLocaleDate,
  formatPercentageBasePoints,
  getPaymentMethodName,
  getPaymentSourceName,
  paymentMethodVariantTranslationMap,
} from '@zentact/common';
import {ExportToCsv} from '@zentact/ui-tailwind';

export const exportPaymentInterchangeCSV = (
  list: InterchangePaymentsListOutput,
  startDate: Date,
  endDate: Date,
  i18n: I18n
) => {
  const dates = `${formatLocaleDate(startDate, 'short')}-${formatLocaleDate(endDate, 'short')}`
    .toLowerCase()
    .replaceAll(' ', '-');
  const exporter = new ExportToCsv({
    filename: `payemnent-interchange-report-${dates}`,
    showLabels: true,
    useKeysAsHeaders: true,
  });

  if (!list.rows.length) {
    throw new Error(t`No data to export`);
  }

  exporter.generateCsv(
    list.rows.map(row => ({
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Fee Name`]: row.feeName,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Payment Method`]: getPaymentMethodName(row.paymentMethod, i18n.locale as LocaleCode),
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Payment Method Variant`]:
        paymentMethodVariantTranslationMap.get(row.paymentMethodVariant) ??
        row.paymentMethodVariant,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Source`]: getPaymentSourceName(row.source, i18n.locale as LocaleCode),
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Fixed Fee`]: formatAmountWithoutCurrency(
        Math.round(row.fixedFeeAmount),
        i18n.locale as LocaleCode,
        row.currency as CurrencyCode
      ),
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Percentage Fee`]: formatPercentageBasePoints(
        row.percentageFeeBasePoints,
        i18n.locale as LocaleCode
      ),
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Fee Volume`]: formatAmountWithoutCurrency(
        Math.round(row.feeVolume),
        i18n.locale as LocaleCode,
        row.currency as CurrencyCode
      ),
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Payments Count`]: row.paymentsCount,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Payments Volume`]: formatAmountWithoutCurrency(
        row.paymentsVolume,
        i18n.locale as LocaleCode,
        row.currency as CurrencyCode
      ),
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Currency`]: row.currency,
    }))
  );
};
