import {trpc} from '@/api/trpcClient';
import {zodResolver} from '@hookform/resolvers/zod';
import {i18n} from '@lingui/core';
import {Trans, t} from '@lingui/macro';
import {AddTenantWebhookRequest} from '@zentact/core/src/schemas/webhookSchemas/addWebhookSchema';
import {
  AlertOverlayWithConfirmation,
  Button,
  InputText,
  SlideOverWithBrandedHeader,
  useNotification,
} from '@zentact/ui-tailwind';
import {useCallback, useState} from 'react';
import {useForm} from 'react-hook-form';
import {AddWebhookForm, addWebhookFormSchema} from './add-webhook-form';

export type AddWebhookProps = {
  isOpen: boolean;
  onSuccess: () => void;
  onCancel: () => void;
};

export const AddWebhook = ({isOpen, onSuccess, onCancel}: AddWebhookProps) => {
  const [addHmacKeyConfirmationOpen, setAddHmacKeyConfirmationOpen] = useState(false);
  const [hmacKey, setHmacKey] = useState('');

  const form = useForm<AddTenantWebhookRequest>({
    resolver: zodResolver(addWebhookFormSchema()),
  });
  const {showSuccessNotification, showErrorNotification} = useNotification();

  const {handleSubmit} = form;

  const addWebhookMutation = trpc.webhooks.addTenantWebhook.useMutation({
    onSuccess: () => {
      showSuccessNotification(
        t`New Webhook is created`,
        t`Now you can copy HMAC to your clipboard`
      );
      onSuccess();
    },
    onError: error => {
      onCancel();
      showErrorNotification(i18n._('Error'), error.message);
    },
  });

  const onSubmit = useCallback(async (data: AddTenantWebhookRequest) => {
    const newHmacKey = await addWebhookMutation.mutateAsync({
      ...data,
    });
    setHmacKey(newHmacKey.hmacKey);
    setAddHmacKeyConfirmationOpen(true);
  }, []);

  const onCancelClick = useCallback((_event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onCancel();
    form.reset();
  }, []);

  const copyHmacKey = () => {
    navigator.clipboard.writeText(hmacKey);
    showSuccessNotification(t`HMAC Key copied`);
  };

  return (
    <>
      <SlideOverWithBrandedHeader
        isOpen={isOpen}
        title={t`Create new Webhook`}
        closeHandler={onCancelClick}
        footer={
          <footer className="flex flex-row-reverse p-4 shrink-0 gap-x-3">
            <div className="flex shrink-0 gap-x-3">
              <Button
                variant="primary"
                size="lg"
                className="w-fit"
                onClick={handleSubmit(onSubmit)}
                isLoading={addWebhookMutation.isLoading}
              >
                <Trans>Create New Webhook</Trans>
              </Button>
            </div>
            <Button variant="secondary" size="lg" className="w-fit" onClick={onCancelClick}>
              <Trans>Close</Trans>
            </Button>
          </footer>
        }
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <AddWebhookForm form={form} />
        </form>
      </SlideOverWithBrandedHeader>
      <AlertOverlayWithConfirmation
        open={addHmacKeyConfirmationOpen}
        setOpen={setAddHmacKeyConfirmationOpen}
        disableConfirmButton={false}
        handleAction={copyHmacKey}
        mode="success"
        localeText={{
          title: i18n._('Save your HMAC key'),
          description: i18n._(
            `Please save this HMAC key somewhere safe and accessible. For security reasons, you won't be able to view it again through your Zentact account. If you lose this secret key, you'll need to generate a new one.`
          ),
          confirm: i18n._('Copy'),
          cancel: i18n._('Done'),
        }}
      >
        <div className="px-4">
          <InputText
            className="text-gray-400 placeholder:text-gray-400 sm:text-sm"
            readOnly={true}
            value={hmacKey}
          />
        </div>
      </AlertOverlayWithConfirmation>
    </>
  );
};
