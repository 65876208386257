import {I18nProvider} from '@lingui/react';
import {ErrorCode} from '@zentact/common';
import {Loading, SlideOverWithBrandedHeader, useDeveloperPhrase} from '@zentact/ui-tailwind';
import {Navigate} from 'react-router-dom';

import {useLocale} from '../../hooks/useLocale';
import {DeveloperSidebar} from '../developer/DeveloperSidebar';
import {AppRoutes} from './AppRoutes';
import {Auth0ProviderWithRedirectCallback} from './Auth0ProviderWithRedirectCallback';

const DEVELOPER_PHRASE = [
  'ArrowUp',
  'ArrowUp',
  'ArrowDown',
  'ArrowDown',
  'ArrowLeft',
  'ArrowRight',
  'ArrowLeft',
  'ArrowRight',
  'KeyB',
  'KeyA',
];

export const App = () => {
  const {localeState} = useLocale();
  const [isDeveloper, reset] = useDeveloperPhrase(DEVELOPER_PHRASE);

  if (localeState.isLoading) {
    return <Loading />;
  }

  if (localeState.isError) {
    return <Navigate replace to="/error" state={{errorCode: ErrorCode.ERROR_GENERIC}} />;
  }

  return (
    <Auth0ProviderWithRedirectCallback
      domain={import.meta.env.VITE_AUTH0_DOMAIN}
      clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
      cacheLocation="localstorage"
      authorizationParams={{
        // biome-ignore lint/style/useNamingConvention: Auth0 uses snake case
        redirect_uri: `${window.location.origin}/auth/callback`,
        audience: import.meta.env.VITE_AUTH0_AUDIENCE,
      }}
    >
      <I18nProvider i18n={localeState.data.i18n}>
        <AppRoutes />
        <SlideOverWithBrandedHeader
          isOpen={isDeveloper}
          closeHandler={reset}
          title="Developer Mode"
        >
          <DeveloperSidebar onUpdate={reset} />
        </SlideOverWithBrandedHeader>
      </I18nProvider>
    </Auth0ProviderWithRedirectCallback>
  );
};
