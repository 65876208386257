import {trpc} from '@/api/trpcClient';
import {useStore} from '@/store';
import {zodResolver} from '@hookform/resolvers/zod';
import {Trans, t} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import {
  MerchantAccountDetailsOutput,
  MerchantAccountsListItem,
} from '@zentact/api/src/trpc/routers/merchantAccountRouter';
import {
  Button,
  InputSelect,
  SlideOverWithBrandedHeader,
  ValidationError,
  useNotification,
} from '@zentact/ui-tailwind';
import {useCallback} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {z} from 'zod';

export const getUpdateMerchantSettlementDelayDaysSchema = () =>
  z.object({
    settlementDelayDays: z.string({
      // biome-ignore lint/style/useNamingConvention: Zod uses snake case
      required_error: t`Please select Settlement Delay`,
    }),
  });

export type UpdateMerchantSettlementDelayDaysData = z.infer<
  ReturnType<typeof getUpdateMerchantSettlementDelayDaysSchema>
>;

export type UpdateMerchantSettlementDelayDaysProps = {
  merchantAccountRow: MerchantAccountDetailsOutput | MerchantAccountsListItem;
  onClose: () => void;
  isOpen: boolean;
  triggerRefetch: () => void;
};

export const UpdateMerchantSettlementDelayDays = ({
  merchantAccountRow,
  onClose,
  isOpen,
  triggerRefetch: refetchMerchantAccountsList,
}: UpdateMerchantSettlementDelayDaysProps) => {
  const {tenant} = useStore();
  const {i18n} = useLingui();
  const merchantAccountId =
    'brandConfiguration' in merchantAccountRow
      ? merchantAccountRow.id
      : merchantAccountRow.merchantAccount?.id;
  const merchantAccountSettlementDelay =
    'brandConfiguration' in merchantAccountRow
      ? merchantAccountRow.settlementDelayDaysOverride
      : merchantAccountRow.merchantAccount?.settlementDelayDaysOverride;

  const currentSettlementDelay =
    merchantAccountSettlementDelay?.toString() || tenant.settlementDelayDays?.toString();

  const updateMerchantEmailNotificationsForm = useForm<UpdateMerchantSettlementDelayDaysData>({
    resolver: zodResolver(getUpdateMerchantSettlementDelayDaysSchema()),
    defaultValues: {
      settlementDelayDays: currentSettlementDelay,
    },
  });
  const {
    handleSubmit,
    control,
    formState: {errors},
    watch,
  } = updateMerchantEmailNotificationsForm;
  const {showSuccessNotification, showErrorNotification} = useNotification();

  const settlementDelayDaysWatch = watch('settlementDelayDays');

  const updateMerchantSettlementDelay =
    trpc.merchantAccount.updateMerchantAccountSettlementDelay.useMutation({
      onSuccess: () => {
        refetchMerchantAccountsList();
        onClose();
        showSuccessNotification(
          t`Merchant settlement delay updated!`,
          t`You have successfully updated merchant settlement delay.`
        );
      },
      onError: error => {
        showErrorNotification(t`Error`, error.message);
      },
    });

  const onUpdateMerchantSettlementDelaySubmit = useCallback(
    (data: UpdateMerchantSettlementDelayDaysData) => {
      if (!merchantAccountId) {
        return;
      }
      updateMerchantSettlementDelay.mutate({
        merchantAccountId: merchantAccountId,
        settlementDelayDays: parseInt(data.settlementDelayDays),
      });
    },
    [merchantAccountId]
  );

  const options = [
    {
      id: '1',
      label: 'T+1',
    },
    {
      id: '2',
      label: 'T+2',
    },
    {
      id: '3',
      label: 'T+3',
    },
  ];

  return (
    <SlideOverWithBrandedHeader
      isOpen={isOpen}
      title={i18n._('Adjust Settlement Delay Settings')}
      text={i18n._(
        'Customize the duration it takes for transactions to be settled. This setting allows you to manage the timing between transaction authorization and fund settlement in merchant accounts. Please note, depending on your contract with Adyen, opting for quicker settlements may incur higher charges.'
      )}
      closeHandler={onClose}
      footer={
        <footer className="flex flex-row-reverse p-4 shrink-0 gap-x-3">
          <div className="flex shrink-0 gap-x-3">
            <Button
              variant="primary"
              size="lg"
              className="w-fit"
              onClick={handleSubmit(onUpdateMerchantSettlementDelaySubmit)}
              disabled={settlementDelayDaysWatch === currentSettlementDelay}
              isLoading={updateMerchantSettlementDelay.isLoading}
            >
              <Trans>Update Merchant</Trans>
            </Button>
          </div>
          <Button variant="secondary" size="lg" className="w-fit" onClick={onClose}>
            <Trans>Close</Trans>
          </Button>
        </footer>
      }
    >
      <form onSubmit={handleSubmit(onUpdateMerchantSettlementDelaySubmit)}>
        <div>
          <div className="flex flex-col gap-3">
            <Controller
              name="settlementDelayDays"
              control={control}
              render={({field}) => (
                <InputSelect
                  {...field}
                  className="mb-2"
                  label={t`Settlement Delay`}
                  options={options}
                  placeholder={t`Please select the value`}
                />
              )}
            />
            <ValidationError isVisible={Boolean(errors.settlementDelayDays)}>
              {errors.settlementDelayDays?.message}
            </ValidationError>
          </div>
        </div>
      </form>
    </SlideOverWithBrandedHeader>
  );
};
