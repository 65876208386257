import {LocaleCode} from '../../constants';

export const formatPercentageBasePoints = (
  basePoints: number,
  locale: LocaleCode,
  decimals: number | undefined = 2
): string => {
  if (basePoints === 0 || Number.isNaN(basePoints)) {
    return '0%'; // Return '0%' instead of 'NaN%'
  }

  const formatter = new Intl.NumberFormat(locale, {
    style: 'percent',
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });

  return formatter.format(basePoints / 10_000);
};
