import {RoutePath} from '@/components/layout/navigation';
import {I18n} from '@lingui/core';
import {Trans} from '@lingui/macro';
import {createColumnHelper} from '@tanstack/react-table';
import {OrganizationListItem} from '@zentact/api/src/trpc/routers/organizationRouter';
import {TableColumnSize, formatLocaleDate} from '@zentact/common';
import {
  FlatPillWithDot,
  HighlightedText,
  Tooltip,
  TruncatedText,
  getLocalizedOrganizationStatusMap,
  getTableMeta,
  organizationStatusToColor,
  truncateText,
} from '@zentact/ui-tailwind';
import {Link} from 'react-router-dom';
import {OrganzationActions} from './organization-actions';

const columnsHelper = createColumnHelper<OrganizationListItem>();

type Props = {
  refetchOrganizationList: () => void;
  i18n: I18n;
  openDetailsPanel: (row: OrganizationListItem) => void;
};
export const getOrganizationListColumns = ({
  refetchOrganizationList,
  i18n,
  openDetailsPanel,
}: Props) => {
  const statusLabelMap = getLocalizedOrganizationStatusMap(i18n);

  const organizationNameColumn = columnsHelper.display({
    cell: props => {
      const {filterValues} = getTableMeta(props.table);
      const searchString = filterValues?.name as string;
      const textContent = props.row.original.name || '-';

      return textContent.length > 20 ? (
        <Tooltip text={textContent}>
          <HighlightedText text={truncateText(textContent)} highlight={searchString} />
        </Tooltip>
      ) : (
        <HighlightedText text={textContent} highlight={searchString} />
      );
    },
    size: TableColumnSize.S,
    header: () => <Trans>Organization</Trans>,
    id: 'organizationName',
  });
  const actionsColumn = columnsHelper.display({
    id: 'actions',
    cell: props => (
      <OrganzationActions
        organizationRow={props.row.original}
        refetchOrganizationList={refetchOrganizationList}
        viewType="dots-button"
        openDetailsPanel={openDetailsPanel}
      />
    ),
    size: TableColumnSize.ACTIONS,
  });

  return [
    organizationNameColumn,
    columnsHelper.display({
      id: 'merchants',
      cell: info => (
        <Link
          className="flex gap-x-1"
          to={`${RoutePath.CUSTOMERS_MERCHANTS}?organizationId=${info.row.original.id}`}
        >
          <span className="text-green-500">{info.row.original.activeMerchantAccountsCount}</span>/
          <span className="text-orange-500">
            {info.row.original.onboardingMerchantAccountsCount}
          </span>
          /<span className="text-red-500">{info.row.original.onboardingErrorsMerchantsCount}</span>
        </Link>
      ),
      header: () => <Trans>Merchants</Trans>,
      meta: {
        sort: {
          isSortable: true,
        },
        headerTooltip: i18n._(
          'The column shows the total number of merchants categorized as active/onboarding/and merchants with onboarding errors'
        ),
        visibleAt: 'lg',
      },
      size: TableColumnSize.S,
    }),
    columnsHelper.accessor('status', {
      cell: info => (
        <FlatPillWithDot
          color={organizationStatusToColor[info.getValue()] || 'blue'}
          label={statusLabelMap[info.getValue()] || info.getValue()}
        />
      ),
      meta: {
        collapseAt: 'lg',
      },
      header: () => <Trans>Status</Trans>,
      size: TableColumnSize.S,
    }),
    columnsHelper.accessor('updatedAt', {
      cell: info => formatLocaleDate(info.getValue(), 'shortWithTime'),
      header: () => <Trans>Last Active</Trans>,
      meta: {
        visibleAt: 'md',
        sort: {
          isSortable: true,
        },
      },
      size: TableColumnSize.S,
    }),
    columnsHelper.accessor('supportEmail', {
      cell: info => <TruncatedText text={info.getValue() || i18n._('N/A')} />,
      header: () => <Trans>Support Email</Trans>,
      size: TableColumnSize.XS,
    }),
    actionsColumn,
  ];
};
