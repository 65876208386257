import {useCallback} from 'react';
import {cn} from '../..';

type Props<Key extends string> = {
  items: Array<{title: string; key: Key}>;
  activeTabKey: Key;
  onChange: (newActiveTabKey: Key) => void;
};
export const NavigationTabs = <Key extends string>({activeTabKey, items, onChange}: Props<Key>) => {
  const onTabClick = useCallback(
    (newActiveTabKey: Key) => {
      if (newActiveTabKey !== activeTabKey) {
        onChange(newActiveTabKey);
      }
    },
    [activeTabKey, onChange]
  );

  return (
    <div className="flex overflow-hidden font-medium gap-x-3">
      {items.map(({title, key}) => (
        // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
        <div
          key={`tab-${key}`}
          className={cn(
            'py-2 px-3 rounded-t-lg bg-gray-300/80 shadow transition-colors duration-200 ease-in-out',
            activeTabKey === key ? 'bg-white' : 'cursor-pointer hover:bg-gray-200'
          )}
          onClick={() => onTabClick(key)}
        >
          {title}
        </div>
      ))}
    </div>
  );
};
