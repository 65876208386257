import {TrpcProvider} from '@/api/TrpcProvider';
import {Notification} from '@zentact/ui-tailwind';
import '@zentact/ui-tailwind/fonts';
import LogRocket from 'logrocket';
import {SnackbarProvider} from 'notistack';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import {App} from './components/app/App';
import './index.css';
import {StoreProvider} from './store';

if (import.meta.env.VITE_LOGROCKET_ID) {
  LogRocket.init(import.meta.env.VITE_LOGROCKET_ID);
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <BrowserRouter>
    <TrpcProvider>
      <StoreProvider>
        <SnackbarProvider
          maxSnack={1}
          Components={{
            success: Notification,
            info: Notification,
            warning: Notification,
            error: Notification,
          }}
          domRoot={document.getElementById('snackbar-root') as HTMLElement}
        >
          <App />
        </SnackbarProvider>
      </StoreProvider>
    </TrpcProvider>
  </BrowserRouter>
);
