import {trpc} from '@/api/trpcClient';
import {zodResolver} from '@hookform/resolvers/zod';
import {i18n} from '@lingui/core';
import {Trans, t} from '@lingui/macro';
import {TenantWebhookListItemOutput} from '@zentact/api/src/trpc/routers/webhookRouter';
import {AddTenantWebhookRequest} from '@zentact/core';
import {Button, SlideOverWithBrandedHeader, useNotification} from '@zentact/ui-tailwind';
import {useCallback} from 'react';
import {useForm} from 'react-hook-form';
import {AddWebhookForm, addWebhookFormSchema} from './add-webhook-form';

export type UpdateMerchantAccountFormProps = {
  row: TenantWebhookListItemOutput;
  onClose: () => void;
  isOpen: boolean;
  refetch: () => void;
};

export const EditWebhook = ({row, onClose, isOpen, refetch}: UpdateMerchantAccountFormProps) => {
  const form = useForm<AddTenantWebhookRequest>({
    resolver: zodResolver(addWebhookFormSchema()),
    defaultValues: {
      name: row.name || undefined,
      url: row.url,
      eventTypes: row.eventTypes,
    },
  });
  const {showSuccessNotification, showErrorNotification} = useNotification();

  const {handleSubmit} = form;

  const editWebhookMutation = trpc.webhooks.editTenantWebhookConfig.useMutation({
    onSuccess: () => {
      refetch();
      showSuccessNotification(t`Webhook is updated`);
      onClose();
    },
    onError: error => {
      onClose();
      showErrorNotification(i18n._('Error'), error.message);
    },
  });

  const onSubmit = (data: AddTenantWebhookRequest) => {
    editWebhookMutation.mutate({
      id: row.id,
      ...data,
    });
  };

  const onCancelClick = useCallback((_event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onClose();
    form.reset();
  }, []);

  return (
    <SlideOverWithBrandedHeader
      isOpen={isOpen}
      title={t`Edit Webhook`}
      closeHandler={onCancelClick}
      footer={
        <footer className="flex flex-row-reverse p-4 shrink-0 gap-x-3">
          <div className="flex shrink-0 gap-x-3">
            <Button
              variant="primary"
              size="lg"
              className="w-fit"
              onClick={handleSubmit(onSubmit)}
              isLoading={editWebhookMutation.isLoading}
            >
              <Trans>Edit Webhook</Trans>
            </Button>
          </div>
          <Button variant="secondary" size="lg" className="w-fit" onClick={onCancelClick}>
            <Trans>Close</Trans>
          </Button>
        </footer>
      }
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <AddWebhookForm form={form} />
      </form>
    </SlideOverWithBrandedHeader>
  );
};
