import {trpc} from '@/api/trpcClient';
import {useMemo} from 'react';

export const useSplitConfigurationGroups = (isActiveOnly = true) => {
  const {data: splitConfigurationGroups} = trpc.splitConfigurationGroup.list.useQuery(
    {
      where: {
        ...(isActiveOnly ? {status: ['ACTIVE']} : {}),
      },
    },
    {
      refetchOnWindowFocus: true,
    }
  );
  const defaultSplitConfigurationGroup = useMemo(() => {
    if (!splitConfigurationGroups?.rows?.length) {
      return;
    }

    return splitConfigurationGroups.rows.find(group => group.isDefault);
  }, [splitConfigurationGroups]);

  return {
    splitConfigurationGroups: splitConfigurationGroups?.rows ?? [],
    defaultSplitConfigurationGroup,
  };
};

export const useDefaultSplitConfigurationGroup = () => {
  const {data: defaultSplitConfigurationGroup, refetch} =
    trpc.splitConfigurationGroup.tenantDefaultSplitConfigurationGroup.useQuery();

  return {defaultSplitConfigurationGroup, refetch};
};
