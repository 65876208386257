import {useAuth0} from '@auth0/auth0-react';
import {Trans} from '@lingui/macro';
import {Loading} from '@zentact/ui-tailwind';

export const AuthCallback = () => {
  const {error, logout} = useAuth0();

  if (!error) {
    return <Loading />;
  }

  return (
    <section className="bg-primary-400 dark:bg-primary-900">
      <div className="container flex items-center justify-center min-h-screen px-6 py-12 mx-auto">
        <div className="flex flex-col items-center justify-center">
          <h1 className="text-2xl font-extrabold text-center text-white md:text-6xl">
            <Trans>There was a problem</Trans>
          </h1>
          <p className="mt-10 text-xl text-center text-white">
            <Trans>Something went wrong, if you need more help, please contact support.</Trans>
          </p>
          <p className="mt-4 text-base text-center text-white">
            {error && <Trans>Authentication Error: {error.message}</Trans>}
          </p>

          <div className="mt-16 md:gap-x-3">
            <button
              type="button"
              onClick={() => {
                logout({logoutParams: {returnTo: `${window.location.origin}/logout`}});
              }}
              className="w-auto px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 rounded-lg bg-primary-500 hover:bg-primary-600 dark:bg-primary-800 dark:hover:bg-primary-700 shrink-0"
            >
              <Trans>Log out</Trans>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};
