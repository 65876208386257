import {trpc} from '@/api/trpcClient';
import {RoutePath} from '@/components/layout/navigation';
import {useStore} from '@/store';
import {XCircleIcon} from '@heroicons/react/24/outline';
import {Plural, Trans, t} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import {MerchantStoppedProcessingListItem} from '@zentact/api/src/trpc/routers/reportingRouter';
import {
  Breadcrumbs,
  Button,
  EntityPicker,
  Typography,
  useNotification,
  useTypedSearchParams,
} from '@zentact/ui-tailwind';
import {useCallback, useEffect, useState} from 'react';
import {generatePath, useNavigate} from 'react-router-dom';
import {z} from 'zod';
import {MerchantStoppedProcessingList} from './merchant-stopped-processing-list';
import {exportToCsvStoppedProcessingMerchants} from './merchant-stopped-processing-list/export-to-csv-stopped-processing-merchants';

const breadcrumbs = () => [
  {name: t`Risk`, href: RoutePath.RISK, current: false},
  {name: t`Merchants Stopped Processing`, href: '#', current: true},
];

export const MerchantStoppedProcessing = () => {
  const {orgsWithBoardedMerchants: organizationList} = useStore();
  const navigate = useNavigate();
  const {i18n} = useLingui();

  const searchParamsSchema = z.object({
    organizationId: z.string().nullable().optional(),
    businessName: z.string().nullable().optional(),
    sortColumnId: z.string().nullable().optional(),
    sortValue: z.literal('asc').or(z.literal('desc')).nullable().optional(),
  });

  const {typedSearchParams, setTypedSearchParams} = useTypedSearchParams(searchParamsSchema);

  const organizationId = typedSearchParams?.organizationId;
  const businessName = typedSearchParams?.businessName;
  const sort =
    typedSearchParams.sortColumnId && typedSearchParams.sortValue
      ? {
          columnId: typedSearchParams.sortColumnId,
          value: typedSearchParams.sortValue,
        }
      : null;

  const [pagination, setPagination] = useState({pageIndex: 0, pageSize: 25});
  const {showSuccessNotification, showErrorNotification} = useNotification();
  const merchantList = trpc.reporting.getMerchantsStoppedProcessingList.useQuery(
    {
      ...pagination,
      ...(sort && {orderBy: {columnId: sort.columnId, value: sort.value}}),
      where: {
        ...(organizationId && {organizationId}),
        ...(businessName && {businessName}),
      },
    },
    {keepPreviousData: true, refetchOnWindowFocus: false, refetchOnReconnect: false}
  );

  const handleOpenMerchantDetails = useCallback(
    (row: MerchantStoppedProcessingListItem) => {
      navigate(
        generatePath(RoutePath.MERCHANT_DETAILS, {merchantAccountId: row.merchantAccountId})
      );
    },
    [navigate, generatePath]
  );

  useEffect(() => {
    setPagination(prev => ({...prev, pageIndex: 0}));
  }, [typedSearchParams]);

  const handleSelectOrganization = useCallback(
    (organizationId?: string) => {
      setTypedSearchParams({organizationId});
    },
    [setTypedSearchParams]
  );

  const handleClearFilters = useCallback(() => {
    setTypedSearchParams({});
  }, []);

  const trpcContext = trpc.useUtils();
  const [isCsvLoading, setCsvLoading] = useState(false);
  const handleCsvExport = useCallback(async () => {
    setCsvLoading(true);
    try {
      const fullMerchantsList = await trpcContext.reporting.getMerchantsStoppedProcessingList.fetch(
        {
          ...(sort && {orderBy: {columnId: sort.columnId, value: sort.value}}),
          where: {
            ...(organizationId && {organizationId}),
            ...(businessName && {businessName}),
          },
        }
      );
      exportToCsvStoppedProcessingMerchants(fullMerchantsList, i18n);
      showSuccessNotification(t`Payments .csv file exported`);
    } catch (e) {
      showErrorNotification(t`Payments .csv export failed`, (e as Error).message);
    }
    setCsvLoading(false);
  }, [
    setCsvLoading,
    trpcContext,
    exportToCsvStoppedProcessingMerchants,
    sort,
    typedSearchParams,
    i18n,
  ]);
  const filtersAmount = (status?.length ?? 0) + (businessName ? 1 : 0) + (organizationId ? 1 : 0);

  return (
    <div className="flex flex-col">
      <Breadcrumbs pages={breadcrumbs()} />
      <div className="flex justify-between gap-2 py-4 max-md:flex-col md:items-center">
        <Typography variant="header-page" className="flex">
          <Trans>Merchants Stopped Processing</Trans>
        </Typography>
        <div className="flex gap-2 font-normal max-sm:flex-wrap md:items-center">
          {filtersAmount >= 1 && (
            <div className="flex items-center justify-between space-x-2 overflow-hidden rounded-md bg-white font-semibold shadow ring-1 ring-gray-300 p-1.5 md:text-sm">
              <span className="pl-0.5">
                <Trans>
                  {filtersAmount}{' '}
                  <Plural value={filtersAmount} one="filter applied" other="filters applied" />
                </Trans>
              </span>
              <button type="button" className="shrink-0" onClick={handleClearFilters}>
                <XCircleIcon className="w-5 h-5 text-gray-400 hover:text-gray-600" />
              </button>{' '}
            </div>
          )}
          <div className="font-normal shrink-0 max-sm:w-full">
            <EntityPicker
              selected={organizationId || ''}
              onChange={handleSelectOrganization}
              options={organizationList}
            />
          </div>
          <Button
            type="button"
            variant="primary"
            size="md"
            className="w-fit max-md:w-full whitespace-nowrap"
            isLoading={isCsvLoading}
            onClick={handleCsvExport}
            disabled={!merchantList.data || merchantList.data.rows.length === 0}
          >
            <Trans>Export to CSV</Trans>
          </Button>
        </div>
      </div>
      <div className="text-sm leading-6 text-gray-500 ">
        <Trans>
          This report highlights accounts that have stopped processing payments for more than 30
          days. To minimize risk, these stale accounts should be reviewed and closed.
        </Trans>
      </div>
      <div className="mt-4">
        <MerchantStoppedProcessingList
          merchantList={merchantList.data}
          filters={typedSearchParams || {}}
          setFilters={setTypedSearchParams}
          sort={sort}
          setSort={newSort => {
            setTypedSearchParams({
              sortColumnId: newSort?.columnId,
              sortValue: newSort?.value,
            });
          }}
          pagination={pagination}
          onPaginationChange={setPagination}
          openDetails={handleOpenMerchantDetails}
          isLoading={merchantList.isLoading}
        />
      </div>
    </div>
  );
};
