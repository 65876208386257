import {AsyncState} from '@/types/AsyncState';
import {Messages, i18n} from '@lingui/core';
import {detect, fromNavigator, fromStorage, fromUrl} from '@lingui/detect-locale';
import {LocaleCode} from '@zentact/common';
import {useCallback, useEffect, useState} from 'react';

export type LocaleState = {code: LocaleCode; i18n: typeof i18n};

const localeModules = import.meta.glob('../locale/**/messages.ts', {
  import: 'messages',
  eager: true,
});

const DEFAULT_LOCALE = LocaleCode.en_US;

const dynamicActivateLocale = async (locale: LocaleCode = DEFAULT_LOCALE) => {
  const messages = localeModules[`../locale/${locale}/messages.ts`] as Messages;
  i18n.load(locale, messages);
  i18n.activate(locale);
};

export const useLocale = () => {
  const [state, setState] = useState<AsyncState<LocaleState>>({
    isLoading: true,
    isError: false,
  });

  const detectLocale = detect(
    fromUrl('l'),
    fromStorage('l'),
    fromNavigator(),
    () => DEFAULT_LOCALE
  ) as LocaleCode;

  const setLocale = useCallback((locale: LocaleCode) => {
    dynamicActivateLocale(locale)
      .catch(() => dynamicActivateLocale(DEFAULT_LOCALE))
      .then(() => {
        setState({
          isLoading: false,
          isError: false,
          data: {code: locale, i18n},
        });
      })
      .catch(err => {
        console.error('Failed to load locale with an error:', err);
        setState({
          isLoading: false,
          isError: true,
          error: {
            code: 'ERROR_LOADING_LOCALE',
            message: err.toString(),
          },
        });
      });
  }, []);

  useEffect(() => {
    setLocale(detectLocale);
  }, [detectLocale]);

  return {
    locale: !state.isLoading && !state.isError ? state.data.code : DEFAULT_LOCALE,
    localeState: state,
    setLocale,
  };
};
