import {I18n} from '@lingui/core';
import {createColumnHelper} from '@tanstack/react-table';
import {InterchangePaymentListItem} from '@zentact/api/src/trpc/routers/paymentRouter';
import {
  CurrencyCode,
  LocaleCode,
  TableColumnSize,
  formatAmount,
  formatPercentageBasePoints,
  getPaymentSourceName,
  paymentMethodVariantTranslationMap,
} from '@zentact/common';
import {
  HighlightedText,
  PaymentMethodIcon,
  TableSearchFilter,
  Tooltip,
  TruncatedText,
  getTableMeta,
  truncateText,
} from '@zentact/ui-tailwind';

export const getEnhancedSchemeDataLevelTranslation = (level: string | null, i18n: I18n) => {
  switch (level) {
    case 'L2':
      return i18n._('Level L2 Fees');
    case 'L3':
      return i18n._('Level L3 Fees');
    default:
      return i18n._('Level None Fees');
  }
};

const columnsHelper = createColumnHelper<InterchangePaymentListItem>();

type Props = {
  locale: LocaleCode;
  i18n: I18n;
};

export const getPaymentColumns = ({locale, i18n}: Props) => {
  const renderPaymentMethod = (paymentMethod: string) => (
    <div className="flex items-center gap-2">
      <PaymentMethodIcon paymentType={paymentMethod} onlyPrimaryPart />
      {paymentMethod === 'ach' ? <span>ACH</span> : <span />}
    </div>
  );
  return [
    columnsHelper.display({
      cell: props => {
        const {filterValues} = getTableMeta(props.table);
        const searchString = filterValues?.feeName as string;
        const textContent = props.row.original.feeName || '';

        return textContent.length > 30 ? (
          <Tooltip text={textContent}>
            <HighlightedText text={truncateText(textContent, 30)} highlight={searchString} />
          </Tooltip>
        ) : (
          <HighlightedText text={textContent} highlight={searchString} />
        );
      },
      id: 'feeName',
      header: () => i18n._('Fee Name'),
      meta: {
        filter: {
          filterId: 'feeName',
          renderFilter: ({...filterProps}) => <TableSearchFilter {...filterProps} />,
        },
        sort: {
          isSortable: true,
        },
      },
      size: TableColumnSize.L,
    }),
    columnsHelper.display({
      cell: props => renderPaymentMethod(props.row.original.paymentMethod),
      id: 'paymentMethod',
      header: props =>
        getTableMeta(props.table).breakpoints.md ? i18n._('Payment Method') : i18n._('Payment'),
      size: TableColumnSize.XS,
      meta: {
        collapseAt: 'sm',
        sort: {
          isSortable: true,
        },
      },
    }),
    columnsHelper.display({
      id: 'paymentMethodVariant',
      cell: props => (
        <TruncatedText
          text={
            paymentMethodVariantTranslationMap.get(props.row.original.paymentMethodVariant) ??
            props.row.original.paymentMethodVariant
          }
        />
      ),
      header: () => i18n._('Payment Method Variant'),
      meta: {
        visibleAt: '2xl',
        sort: {
          isSortable: true,
        },
      },
    }),
    columnsHelper.display({
      id: 'source',
      cell: props => getPaymentSourceName(props.row.original.source, locale),
      header: () => i18n._('Source'),
      meta: {
        visibleAt: 'sm',
        collapseAt: 'md',
        sort: {
          isSortable: true,
        },
      },
      size: TableColumnSize.XXS,
    }),
    columnsHelper.display({
      id: 'percentageFeeBasePoints',
      cell: props =>
        `${formatPercentageBasePoints(props.row.original.percentageFeeBasePoints, locale)}${
          getTableMeta(props.table).breakpoints.lg ? '' : ' + '
        }`,
      header: props =>
        getTableMeta(props.table).breakpoints.lg ? i18n._('Percentage Fee') : i18n._('Fee'),
      meta: {
        sort: {
          isSortable: true,
        },
        collapseRowStyle: true,
      },
      size: TableColumnSize.XS,
    }),
    columnsHelper.accessor(
      row => formatAmount(Math.round(row.fixedFeeAmount), locale, row.currency as CurrencyCode),
      {
        id: 'fixedFeeAmount',
        header: i18n._('Fixed Fee'),
        meta: {
          sort: {
            isSortable: true,
          },
          collapseAt: 'lg',
        },
        size: TableColumnSize.XS,
      }
    ),
    columnsHelper.accessor(
      row => formatAmount(Math.round(row.feeVolume), locale, row.currency as CurrencyCode),
      {
        id: 'feeVolume',
        header: i18n._('Fee Volume'),
        meta: {
          sort: {
            isSortable: true,
          },
          visibleAt: 'lg',
        },
        size: TableColumnSize.XS,
      }
    ),
    columnsHelper.accessor(row => row.paymentsCount, {
      id: 'paymentsCount',
      header: i18n._('Payments'),
      meta: {
        sort: {
          isSortable: true,
        },
        visibleAt: 'md',
      },
      size: TableColumnSize.XXS,
    }),
    columnsHelper.accessor(
      row => formatAmount(row.paymentsVolume, locale, row.currency as CurrencyCode),
      {
        id: 'paymentsVolume',
        header: i18n._('Payment Volume'),
        meta: {
          sort: {
            isSortable: true,
          },
          collapseAt: '2xl',
          visibleAt: 'md',
        },
        size: TableColumnSize.S,
      }
    ),
  ];
};
