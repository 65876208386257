import {trpc} from '@/api/trpcClient';
import {i18n} from '@lingui/core';
import {t} from '@lingui/macro';
import {ApiKeysListItemOutput} from '@zentact/api/src/trpc/routers/apiKeysRouter';
import {
  AlertOverlayWithConfirmation,
  DropDownMinimalMenuIcon,
  useNotification,
} from '@zentact/ui-tailwind';
import {useCallback, useState} from 'react';

export type WebHookActionsProps = {
  row: ApiKeysListItemOutput;
  refetch: () => void;
};

export const ApiKeysActions = ({row, refetch}: WebHookActionsProps) => {
  const [deactivateConfirmationOpen, setDeactivateConfirmationOpen] = useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const {showSuccessNotification, showErrorNotification} = useNotification();

  const deactivateMutation = trpc.apiKeys.deactivateApiKey.useMutation({
    onSuccess: () => {
      refetch();
      setDeactivateConfirmationOpen(false);
      showSuccessNotification(
        t`API Key deactivated`,
        t`You have successfully deactivated the API Key.`
      );
    },
    onError: error => {
      showErrorNotification(i18n._('Error'), error.message);
    },
  });

  const activateMutation = trpc.apiKeys.activateApiKey.useMutation({
    onSuccess: () => {
      refetch();
      setDeactivateConfirmationOpen(false);
      showSuccessNotification(
        t`API Key activated`,
        t`You have successfully activated the API Key.`
      );
    },
    onError: error => {
      showErrorNotification(i18n._('Error'), error.message);
    },
  });

  const deleteMutation = trpc.apiKeys.deleteApiKey.useMutation({
    onSuccess: () => {
      refetch();
      setDeactivateConfirmationOpen(false);
      showSuccessNotification(t`API Key deleted`, t`You have successfully deleted the API Key.`);
    },
    onError: error => {
      showErrorNotification(i18n._('Error'), error.message);
    },
  });

  const handleDeactivate = useCallback(() => {
    deactivateMutation.mutate({
      id: row.publicId,
    });
  }, [row]);

  const handleActivate = useCallback(() => {
    activateMutation.mutate({
      id: row.publicId,
    });
  }, [row]);

  const handleDelete = useCallback(() => {
    deleteMutation.mutate({
      id: row.publicId,
    });
  }, [row]);

  const options: {name: string; onClick: () => void; itemClassName?: string}[] = [
    ...(row.enabled
      ? [
          {
            name: 'Deactivate',
            onClick: () => {
              setDeactivateConfirmationOpen(true);
            },
            itemClassName: 'text-red-500',
          },
        ]
      : [
          {
            name: 'Activate',
            onClick: () => {
              handleActivate();
            },
          },
        ]),
    {
      name: 'Delete API Key',
      onClick: () => {
        setDeleteConfirmationOpen(true);
      },
      itemClassName: 'text-red-500',
    },
  ];
  return (
    <>
      <DropDownMinimalMenuIcon items={options} />
      {deactivateConfirmationOpen && (
        <AlertOverlayWithConfirmation
          open={deactivateConfirmationOpen}
          setOpen={setDeactivateConfirmationOpen}
          handleAction={handleDeactivate}
          localeText={{
            title: t`Deactivate API Key`,
            description: t`Are you sure you want to deactivate this API key?`,
            confirm: t`Deactivate`,
            cancel: t`Cancel`,
          }}
        />
      )}
      {deleteConfirmationOpen && (
        <AlertOverlayWithConfirmation
          open={deleteConfirmationOpen}
          setOpen={setDeleteConfirmationOpen}
          handleAction={handleDelete}
          localeText={{
            title: t`Delete API Key`,
            description: t`Are you sure you want to delete this API key?`,
            confirm: t`Delete`,
            cancel: t`Cancel`,
          }}
        />
      )}
    </>
  );
};
