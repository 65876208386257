import {trpc} from '@/api/trpcClient';
import {useLingui} from '@lingui/react';
import {TerminalsOutput} from '@zentact/api/src/trpc/routers/terminalRouter';
import {
  Button,
  EntityPicker,
  InputText,
  Label,
  SlideOverWithBrandedHeader,
  ValidationError,
  useNotification,
} from '@zentact/ui-tailwind';
import {useState} from 'react';

type ManageAssignPanelProps = {
  isOpen: boolean;
  onCancel: () => void;
  refetchTerminalsList: () => void;
  terminalsRow: TerminalsOutput['rows'][0];
};

export const ManageAssignPanel = ({
  isOpen,
  onCancel,
  terminalsRow,
  refetchTerminalsList,
}: ManageAssignPanelProps) => {
  const {i18n} = useLingui();
  const [error, setError] = useState<string>();
  const {showSuccessNotification, showErrorNotification} = useNotification();

  const assignTerminalMutation = trpc.terminal.assign.useMutation({
    onSuccess: () => {
      onCancel();
      showSuccessNotification(
        i18n._('Terminal assigned!'),
        i18n._('You have successfully initiated terminal assignment')
      );
      refetchTerminalsList();
    },
    onError: error => {
      onCancel();
      showErrorNotification(i18n._('Error'), error.message);
    },
  });

  const merchantList =
    trpc.merchantAccount.getMerchantAccountsList
      .useQuery({})
      .data?.rows.reduce<{name: string; id: string}[]>((acc, {merchantAccount}) => {
        if (merchantAccount?.businessName && merchantAccount?.storeId) {
          acc.push({name: merchantAccount.businessName, id: merchantAccount.storeId});
        }
        return acc;
      }, []) || [];

  const [selectedMerchant, setSelectedMerchant] = useState<string | undefined>(
    merchantList.find(merchant => merchant.id === terminalsRow.storeId)?.id
  );

  const handleSubmit = () => {
    if (!selectedMerchant) {
      setError(i18n._('Merchant selection is required'));
      return;
    }
    setError(i18n._(''));

    if (!terminalsRow.id) {
      console.error('Terminals row id is missing');
      return;
    }

    if (selectedMerchant === terminalsRow.storeId) {
      onCancel();
      return;
    }

    assignTerminalMutation.mutate({
      storeId: selectedMerchant,
      terminalId: terminalsRow.id,
    });
  };

  return (
    <SlideOverWithBrandedHeader
      isOpen={isOpen}
      closeHandler={onCancel}
      title={i18n._('Assign Terminal')}
      text={i18n._('Choose Merchant to use Terminal')}
      footer={
        <footer className="flex flex-row-reverse p-4 shrink-0 gap-x-3">
          <div className="flex shrink-0 gap-x-3">
            <Button
              variant="primary"
              size="lg"
              className="w-fit"
              onClick={handleSubmit}
              isLoading={assignTerminalMutation.isLoading}
            >
              {i18n._('Assign Terminal')}
            </Button>
          </div>
          <Button variant="secondary" size="lg" className="w-fit" onClick={onCancel}>
            {i18n._('Close')}
          </Button>
        </footer>
      }
    >
      <div className="flex flex-col gap-3">
        <Label text={i18n._('Serial Number')}>
          <InputText value={terminalsRow.serialNumber} disabled className="opacity-50" />
          <span className="text-sm leading-4 text-gray-400">
            This can be found on the bottom of the device below the barcode.
          </span>
        </Label>
        <div className="text-sm font-medium leading-6 text-gray-90">{i18n._('Merchant')}</div>
        <EntityPicker
          className="w-full"
          excludeDefaultOption
          options={merchantList}
          selected={selectedMerchant}
          onChange={setSelectedMerchant}
          label={i18n._('Choose a merchant...')}
        />
        <ValidationError isVisible={Boolean(error)}>{error}</ValidationError>
      </div>
    </SlideOverWithBrandedHeader>
  );
};
