import {useLocale} from '@/hooks';
import {useLingui} from '@lingui/react';
import {MerchantInternalTransfersListOutput} from '@zentact/api/src/trpc/routers/internalTransferRouter';
import {TableWrapper} from '@zentact/ui-tailwind';
import {getInternalTransfersListColumns} from './columns';

type Props = {
  list?: MerchantInternalTransfersListOutput;
  isLoading?: boolean;
  refetch: () => void;
};

export const InternalTransfersList = ({list, isLoading, refetch}: Props) => {
  const {i18n} = useLingui();
  const {locale} = useLocale();
  return (
    <TableWrapper
      data={list?.rows ?? []}
      columns={getInternalTransfersListColumns({
        i18n,
        locale,
        refetch,
      })}
      isLoading={isLoading}
    />
  );
};
