import {useLingui} from '@lingui/react';
import {TerminalsItem} from '@zentact/api/src/trpc/routers/terminalRouter';
import {SlideOverWithBrandedHeader} from '@zentact/ui-tailwind';

type ManageViewPanelProps = {
  isOpen: boolean;
  onCancel: () => void;
  terminalsRow: TerminalsItem | null;
};

export const ManageViewPanel = ({isOpen, onCancel, terminalsRow}: ManageViewPanelProps) => {
  const {i18n} = useLingui();

  if (!terminalsRow) {
    return null;
  }

  return (
    <SlideOverWithBrandedHeader
      isOpen={isOpen}
      closeHandler={onCancel}
      title={i18n._('View Terminal')}
      text={i18n._('Terminal details')}
    >
      <>
        <div className="border-gray-100">
          <dl className="divide-y divide-gray-100">
            <div className="px-4 pb-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                {i18n._('Device model')}
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 break-words sm:col-span-2 sm:mt-0">
                {terminalsRow.deviceModel}
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                {i18n._('Serial number')}
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 break-words sm:col-span-2 sm:mt-0">
                {terminalsRow.serialNumber}
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                {i18n._('Firmware version')}
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 break-words sm:col-span-2 sm:mt-0">
                {terminalsRow.firmwareVersion}
              </dd>
            </div>
          </dl>
        </div>
      </>
    </SlideOverWithBrandedHeader>
  );
};
