import {trpc} from '@/api/trpcClient';
import {RoutePath} from '@/components/layout/navigation';
import {Trans, t} from '@lingui/macro';
import {apiKeysSchema} from '@zentact/common';
import {
  Breadcrumbs,
  Button,
  TableSortValue,
  Typography,
  useDrawerState,
  useTypedSearchParams,
} from '@zentact/ui-tailwind';
import {useEffect, useState} from 'react';
import {AddApiKey} from './add-api-key';
import {ApiKeysList} from './api-keys-list';

const breadcrumbs = () => [
  {name: t`Configure`, href: RoutePath.CONFIGURE, current: false, disabled: true},
  {name: t`API Keys`, href: '#', current: true},
];

export const ApiKeys = () => {
  const [sort, setSort] = useState<TableSortValue<string>>({columnId: 'createdAt', value: 'desc'});
  const [pagination, setPagination] = useState({pageIndex: 0, pageSize: 15});

  const {typedSearchParams, setTypedSearchParams} = useTypedSearchParams(apiKeysSchema);

  const name = typedSearchParams?.name;

  const apiKeys = trpc.apiKeys.apiKeysList.useQuery(
    {
      ...(sort?.columnId && sort.value && {orderBy: {[sort.columnId]: sort.value}}),
      ...pagination,
      where: {
        ...(name && {name}),
      },
    },
    {refetchOnWindowFocus: true, keepPreviousData: true}
  );

  useEffect(() => {
    setPagination(prev => ({...prev, pageIndex: 0}));
  }, [typedSearchParams, sort]);

  const {
    isOpen: isAddApiKeyFormVisible,
    open: openAddApiKeyForm,
    close: closeAddApiKeyForm,
    onSuccess: onAddApiKeySuccess,
  } = useDrawerState({
    onSuccessHandler: () => {
      apiKeys.refetch();
    },
  });

  return (
    <div className="flex flex-col">
      <Breadcrumbs pages={breadcrumbs()} />
      <div className="flex justify-between gap-2 py-4 max-sm:flex-col sm:items-center">
        <Typography variant="header-page" className="flex">
          <Trans>API Keys</Trans>
        </Typography>
        <Button
          type="button"
          variant="primary"
          size="md"
          onClick={openAddApiKeyForm}
          className="w-fit max-sm:w-full"
        >
          <Trans>Add New</Trans>
        </Button>
      </div>
      <div className="mt-4">
        <ApiKeysList
          apiKeysDataList={apiKeys.data}
          refetch={apiKeys.refetch}
          sort={sort}
          setSort={setSort}
          filters={typedSearchParams}
          setFilters={setTypedSearchParams}
          pagination={pagination}
          onPaginationChange={setPagination}
          isLoading={apiKeys.isLoading || (apiKeys.isRefetching && apiKeys.isPreviousData)}
        />
      </div>
      <AddApiKey
        isOpen={isAddApiKeyFormVisible}
        onCancel={closeAddApiKeyForm}
        onSuccess={onAddApiKeySuccess}
      />
    </div>
  );
};
