import {useLocale} from '@/hooks';
import {Trans} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import {
  MerchantStoppedProcessingListItem,
  MerchantStoppedProcessingListOutput,
} from '@zentact/api/src/trpc/routers/reportingRouter';
import {TableSortValue, TableWrapper} from '@zentact/ui-tailwind';
import {Dispatch, SetStateAction} from 'react';
import {getMerchantStoppedProcessingListColumns} from './columns';

type Props<TFilters extends Record<string, unknown>> = {
  merchantList?: MerchantStoppedProcessingListOutput;
  filters?: TFilters;
  setFilters?: (params: TFilters) => void;
  sort: TableSortValue<string> | null;
  setSort: Dispatch<TableSortValue<string> | null>;
  pagination?: {pageIndex: number; pageSize: number};
  onPaginationChange?: Dispatch<SetStateAction<{pageIndex: number; pageSize: number}>>;
  isLoading?: boolean;
  openDetails?: (row: MerchantStoppedProcessingListItem) => void;
};

export const MerchantStoppedProcessingList = <TFilters extends Record<string, unknown>>({
  merchantList,
  filters,
  setFilters,
  sort,
  setSort,
  pagination,
  onPaginationChange,
  isLoading,
  openDetails,
}: Props<TFilters>) => {
  const {i18n} = useLingui();
  const {locale} = useLocale();
  return (
    <TableWrapper
      data={merchantList?.rows ?? []}
      pageCount={merchantList?.pagination.pageCount ?? 0}
      columns={getMerchantStoppedProcessingListColumns({
        i18n,
        locale,
      })}
      filterValues={filters}
      onFilterChange={({id, value}) => {
        if (setFilters) {
          setFilters({[id]: value} as TFilters);
        }
      }}
      sortValue={sort}
      onSortChange={setSort}
      noDataMessage={
        <div>
          <h3 className="text-sm font-semibold text-gray-900">
            <Trans>No Stopped Processing Merchants</Trans>
          </h3>
          <span className="text-sm text-gray-500">
            <Trans>
              As merchants stop processing payments, they will appear here for you to review.
            </Trans>
          </span>
        </div>
      }
      pagination={pagination}
      onPaginationChange={onPaginationChange}
      onRowClick={openDetails}
      isLoading={isLoading}
    />
  );
};
