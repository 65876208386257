import {trpc} from '@/api/trpcClient';
import {RoutePath} from '@/components/layout/navigation';
import {useStore} from '@/store';
import {Trans, t} from '@lingui/macro';
import {OrganizationUserItem} from '@zentact/api/src/trpc/routers/usersRouter';
import {
  ActiveUsersList,
  Breadcrumbs,
  Button,
  EditActiveUserPanel,
  InviteUser,
  TableSortValue,
  Typography,
  useDrawerState,
  useToggleWithData,
} from '@zentact/ui-tailwind';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

const breadcrumbs = () => [
  {name: t`Users`, href: RoutePath.USERS, current: false},
  {name: t`Active Users`, href: RoutePath.ACTIVE_USERS, current: true},
];

export const ActiveUsers = () => {
  const [filters, setFilters] = useState<{
    status: [];
  }>({status: []});
  const [sort, setSort] = useState<TableSortValue<string> | null>(null);
  const [pagination, setPagination] = useState({pageIndex: 0, pageSize: 25});
  const {tenant} = useStore();
  const navigate = useNavigate();
  const activeUserList = trpc.users.getActiveTenantUsers.useQuery(
    {
      ...pagination,
      ...(sort?.columnId && sort.value && {orderBy: {[sort.columnId]: sort.value}}),
    },
    {
      keepPreviousData: true,
    }
  );

  const {
    isOpen: isInviteUserFormOpen,
    open: openInviteUserForm,
    close: closeInviteUserForm,
    onSuccess: onInviteUserSuccess,
  } = useDrawerState({onSuccessHandler: () => navigate('/users/invited')});

  const {
    data: editUserRow,
    on: openEditActiveUserPanel,
    off: closeEditActiveUserPanel,
  } = useToggleWithData<OrganizationUserItem>();

  // Reset pagination on filter or sort change
  useEffect(() => {
    setPagination(prev => ({...prev, pageIndex: 0}));
  }, [filters, sort]);

  return (
    <div className="flex flex-col">
      <Breadcrumbs pages={breadcrumbs()} />
      <div className="flex pt-4">
        <Typography variant="header-page" className="flex-1">
          <Trans>Active Users</Trans>
        </Typography>
        <div className="flex gap-2 font-normal max-sm:flex-wrap sm:items-center">
          <Button
            type="button"
            variant="primary"
            size="md"
            className="w-fit"
            onClick={openInviteUserForm}
          >
            <Trans>Invite</Trans>
          </Button>
        </div>
      </div>
      <div className="mt-4 overflow-x-auto">
        <ActiveUsersList
          usersList={activeUserList.data}
          activeUsersRefetch={activeUserList.refetch}
          filters={filters}
          setFilters={setFilters}
          sort={sort}
          setSort={setSort}
          pagination={pagination}
          onPaginationChange={setPagination}
          openUserEditPanel={openEditActiveUserPanel}
          noDataMessage={
            <div>
              <h3 className="text-sm font-semibold text-gray-900">
                <Trans>No Active Users</Trans>
              </h3>
              <span className="text-sm text-gray-500">
                <Trans>There is no active users</Trans>
              </span>
            </div>
          }
          isLoading={
            activeUserList.isLoading ||
            (activeUserList.isRefetching && activeUserList.isPreviousData)
          }
          trpc={trpc}
          mode="tenantUser"
        />
      </div>
      <InviteUser
        isOpen={isInviteUserFormOpen}
        onCancel={closeInviteUserForm}
        onSuccess={onInviteUserSuccess}
        organizationOrTenantName={tenant?.name}
        trpc={trpc}
        mode="tenantUser"
      />
      {!!editUserRow && (
        <EditActiveUserPanel
          userRow={editUserRow}
          onSuccess={activeUserList.refetch}
          onClose={closeEditActiveUserPanel}
          trpc={trpc}
          mode="tenantUser"
        />
      )}
    </div>
  );
};
