import {trpc} from '@/api/trpcClient';
import {useStore} from '@/store';
import {t} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import {
  MerchantAccountDetailsOutput,
  MerchantAccountsListItem,
} from '@zentact/api/src/trpc/routers/merchantAccountRouter';
import {formatLocaleDate} from '@zentact/common';
import {
  AlertOverlayWithConfirmation,
  Button,
  MerchantApplicationFileDownload,
  SlideOverWithBrandedHeader,
  useNotification,
} from '@zentact/ui-tailwind';
import {useCallback, useState} from 'react';

export type MerchantReviewPanelProps = {
  isOpen: boolean;
  onCancel: () => void;
  merchantAccountRow: MerchantAccountsListItem | MerchantAccountDetailsOutput | null;
  onSubmit: () => void;
};

export const MerchantReviewPanel = ({
  isOpen,
  onCancel,
  merchantAccountRow,
  onSubmit,
}: MerchantReviewPanelProps) => {
  const [approveConfirmationOpen, setApproveConfirmationOpen] = useState(false);
  const [denyConfirmationOpen, setDenyConfirmationOpen] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [isDeclining, setIsDeclining] = useState(false);
  const {showSuccessNotification, showErrorNotification} = useNotification();
  const {tenant} = useStore();
  const {i18n} = useLingui();

  const sessionId = merchantAccountRow
    ? 'sessionId' in merchantAccountRow
      ? merchantAccountRow.sessionId
      : merchantAccountRow?.merchantAccountRegistration.sessionId
    : null;

  const changeMerchantRegistrationReviewStatus =
    trpc.merchantRegistration.changeReviewStatus.useMutation();
  const approveOrReject = useCallback(
    (merchantAccountRegistrationSessionId: string, isApproved: boolean) => {
      return new Promise<void>((resolve, reject) => {
        changeMerchantRegistrationReviewStatus.mutate(
          {
            merchantAccountRegistrationSessionId,
            status: isApproved ? 'BOARDED' : 'TENANT_REVIEW_REJECTED',
          },
          {
            onSuccess() {
              showSuccessNotification(
                isApproved
                  ? {
                      title: t`Merchant account successfully boarded`,
                      message: t`Merchant account is now fully functional.`,
                    }
                  : {
                      title: t`Merchant account review rejected`,
                    }
              );
            },
            onError() {
              reject();
            },
            onSettled() {
              onSubmit();
              resolve();
            },
          }
        );
      });
    },
    [showErrorNotification, showSuccessNotification]
  );

  const handleApprove = useCallback(async () => {
    if (!sessionId) {
      return;
    }
    setApproveConfirmationOpen(false);
    setIsApproving(true);
    await approveOrReject(sessionId, true);
    setIsApproving(false);
    onCancel();
  }, [sessionId, approveOrReject]);

  const handleDecline = useCallback(async () => {
    if (!sessionId) {
      return;
    }
    setDenyConfirmationOpen(false);
    setIsDeclining(true);
    await approveOrReject(sessionId, false);
    setIsDeclining(false);
    onCancel();
  }, [sessionId]);

  if (!merchantAccountRow) {
    return null;
  }

  const merchantAccount =
    // @ts-ignore fix infinite deep error
    'merchantAccount' in merchantAccountRow
      ? merchantAccountRow.merchantAccount
      : merchantAccountRow;

  const {
    firstName,
    lastName,
    inviteeEmail,
    businessAddressCountry,
    businessAddressCity,
    businessAddressPostalCode,
    businessAddressLine1,
    businessAddressLine2,
    kycCompletedAt,
    createdAt,
    ipAddress,
    uploadedDocumentName,
  } =
    'firstName' in merchantAccountRow
      ? merchantAccountRow
      : merchantAccountRow.merchantAccountRegistration;

  const {businessName, doingBusinessAs, legalBusinessName} = merchantAccount;

  const address = [
    businessAddressCity,
    businessAddressLine1,
    businessAddressLine2,
    businessAddressPostalCode,
  ]
    .filter(v => !!v)
    .join(' ');

  const applicationDocumentFieldName =
    tenant?.features?.askForDocumentsInMerchantApplication?.title;

  return (
    <SlideOverWithBrandedHeader
      isOpen={isOpen}
      title={i18n._('Review Merchant Account')}
      text={i18n._(
        'Review the merchant details to allow them to start taking payments. The merchant has already gone through account verification from the processor.'
      )}
      closeHandler={onCancel}
      footer={
        <footer className="flex justify-between p-4 shrink-0 gap-x-3">
          <div className="flex shrink-0 gap-x-3">
            <Button
              variant="primary"
              size="lg"
              className="bg-red-600 w-fit hover:bg-red-500 focus:ring-red-500"
              onClick={() => setDenyConfirmationOpen(true)}
              isLoading={isDeclining}
              disabled={isApproving}
            >
              {i18n._('Deny Account')}
            </Button>
            <Button
              variant="primary"
              size="lg"
              className="w-fit"
              onClick={() => setApproveConfirmationOpen(true)}
              isLoading={isApproving}
              disabled={isDeclining}
            >
              {i18n._('Approve Account')}
            </Button>
          </div>
          <Button variant="secondary" size="lg" className="w-fit" onClick={onCancel}>
            {i18n._('Close')}
          </Button>
        </footer>
      }
    >
      <>
        <div className="border-gray-100">
          <dl className="divide-y divide-gray-100">
            <div className="px-4 pb-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                {i18n._('Merchant Account')}
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 break-words sm:col-span-2 sm:mt-0">
                {businessName}
              </dd>
            </div>
            {!!merchantAccountRow.organization && (
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  {i18n._('Organization')}
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 break-words sm:col-span-2 sm:mt-0">
                  {merchantAccountRow.organization.name}
                </dd>
              </div>
            )}
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                {i18n._('Legal Business Name')}
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 break-words sm:col-span-2 sm:mt-0">
                {legalBusinessName}
              </dd>
            </div>
            {doingBusinessAs && legalBusinessName !== doingBusinessAs && (
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  {i18n._('Doing Business As')}
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 break-words sm:col-span-2 sm:mt-0">
                  {doingBusinessAs}
                </dd>
              </div>
            )}
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">{i18n._('Country')}</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 break-words sm:col-span-2 sm:mt-0">
                {businessAddressCountry}
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">{i18n._('Address')}</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 break-words sm:col-span-2 sm:mt-0">
                {address}
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">{i18n._('Signatory')}</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 break-words sm:col-span-2 sm:mt-0">
                {firstName} {lastName}
              </dd>
            </div>
            {inviteeEmail && (
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  {i18n._('Contact Email')}
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 break-words sm:col-span-2 sm:mt-0">
                  {inviteeEmail}
                </dd>
              </div>
            )}
            {sessionId && applicationDocumentFieldName && !!uploadedDocumentName && (
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  {applicationDocumentFieldName}
                </dt>
                <dd className="flex items-center mt-1 text-sm leading-6 text-gray-700 break-words sm:col-span-2 sm:mt-0">
                  <div className="flex items-center gap-x-4">
                    {uploadedDocumentName}
                    <MerchantApplicationFileDownload
                      registrationSessionId={sessionId}
                      trpc={trpc}
                    />
                  </div>
                </dd>
              </div>
            )}
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                {i18n._('KYC Completed On')}
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 break-words sm:col-span-2 sm:mt-0">
                {kycCompletedAt && formatLocaleDate(kycCompletedAt)}
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                {i18n._('Created Date')}
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 break-words sm:col-span-2 sm:mt-0">
                {formatLocaleDate(createdAt)}
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                {i18n._('IP Address')}
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 break-words sm:col-span-2 sm:mt-0">
                {ipAddress}
              </dd>
            </div>
          </dl>
        </div>
        <AlertOverlayWithConfirmation
          open={approveConfirmationOpen}
          setOpen={setApproveConfirmationOpen}
          handleAction={handleApprove}
          mode="success"
          localeText={{
            title: i18n._('Approve Account'),
            description: i18n._('Are you sure you want to approve this merchant account?'),
            confirm: i18n._('Approve'),
            cancel: i18n._('Cancel'),
          }}
        />
        <AlertOverlayWithConfirmation
          open={denyConfirmationOpen}
          setOpen={setDenyConfirmationOpen}
          handleAction={handleDecline}
          localeText={{
            title: i18n._('Deny Account'),
            description: i18n._(
              'Are you sure you want to deny this merchant account? You will be able to change review status again later.'
            ),
            confirm: i18n._('Confirm'),
            cancel: i18n._('Cancel'),
          }}
        />
      </>
    </SlideOverWithBrandedHeader>
  );
};
