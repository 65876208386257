import {useStore} from '@/store';
import {Trans} from '@lingui/macro';
import {
  OtherRevenueOutput,
  OtherRevenueOutputItem,
} from '@zentact/api/src/trpc/routers/reportingRouter';
import {TableSortValue, TableWrapper} from '@zentact/ui-tailwind';
import {Dispatch, SetStateAction} from 'react';
import {getOtherRevenueColumns} from './columns';

type Props = {
  data?: OtherRevenueOutput;
  sort: TableSortValue<string> | null;
  setSort: Dispatch<TableSortValue<string> | null>;
  isLoading?: boolean;
  pagination?: {pageIndex: number; pageSize: number};
  onPaginationChange?: Dispatch<SetStateAction<{pageIndex: number; pageSize: number}>>;
  openDetailsPanel: (item: OtherRevenueOutputItem) => void;
};

export const OtherRevenueList = ({
  data,
  isLoading,
  pagination,
  sort,
  setSort,
  onPaginationChange,
  openDetailsPanel,
}: Props) => {
  const {locale} = useStore();
  return (
    <TableWrapper
      data={data?.rows ?? []}
      columns={getOtherRevenueColumns({locale, openDetailsPanel})}
      noDataMessage={
        <div>
          <h3 className="text-sm font-semibold text-gray-900">
            <Trans>No records</Trans>
          </h3>
          <span className="text-sm text-gray-500">
            <Trans>
              Newly collected monthly, annual, chargeback and refund fees will appear here.
            </Trans>
          </span>
        </div>
      }
      isLoading={isLoading}
      pagination={pagination}
      onPaginationChange={onPaginationChange}
      pageCount={data?.pagination.pageCount ?? 0}
      sortValue={sort}
      onSortChange={setSort}
    />
  );
};
