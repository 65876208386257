import {useCallback} from 'react';
import {cn} from '..';
type Item = {
  key: string;
  label: string;
  isActive: boolean;
};
export type RowFiltersProps = {
  items: Item[];
  onChange: (changedItems: Item[]) => void;
};
export const RowFilters = ({items, onChange}: RowFiltersProps) => {
  const onItemClick = useCallback(
    (itemKey: string) => {
      onChange(
        items.map(item => (item.key === itemKey ? {...item, isActive: !item.isActive} : item))
      );
    },
    [items]
  );
  return (
    <div className="flex flex-wrap pl-0.5">
      {items.map((item, index) => {
        const isFirst = index === 0;
        const isLast = index === items.length - 1;
        return (
          // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
          <div
            key={`filter-${item.key}`}
            className={cn(
              '-ms-0.5 p-2 border border-gray-200 text-xs font-medium cursor-pointer',
              item.isActive ? 'bg-white hover:bg-gray-50' : 'bg-gray-100 hover:bg-gray-200',
              isFirst && 'rounded-l',
              isLast && 'rounded-r '
            )}
            onClick={() => onItemClick(item.key)}
          >
            {item.label}
          </div>
        );
      })}
    </div>
  );
};
