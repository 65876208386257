import {useLingui} from '@lingui/react';
import {InterchangePaymentsListOutput} from '@zentact/api/src/trpc/routers/paymentRouter';
import {LocaleCode} from '@zentact/common';
import {Dispatch, SetStateAction} from 'react';
import {TableSortValue, TableWrapper} from '../../../table';
import {getPaymentColumns} from './columns';

type Props<TFilters extends Record<string, unknown>> = {
  paymentsList?: InterchangePaymentsListOutput;
  filters?: TFilters;
  setFilters?: Dispatch<TFilters>;
  sort?: TableSortValue<string> | null;
  setSort?: Dispatch<TableSortValue<string>>;
  pagination?: {pageIndex: number; pageSize: number};
  onPaginationChange?: Dispatch<SetStateAction<{pageIndex: number; pageSize: number}>>;
  isLoading?: boolean;
};

export const InterchangePaymentsList = <TFilters extends Record<string, unknown>>({
  paymentsList,
  filters,
  setFilters,
  sort,
  setSort,
  pagination,
  onPaginationChange,
  isLoading,
}: Props<TFilters>) => {
  const {i18n} = useLingui();
  return (
    <TableWrapper
      data={paymentsList?.rows ?? []}
      pageCount={paymentsList?.pagination.pageCount ?? 0}
      columns={getPaymentColumns({
        locale: i18n.locale as LocaleCode,
        i18n,
      })}
      filterValues={filters}
      onFilterChange={({id, value}) => {
        if (setFilters) {
          setFilters({[id]: value} as TFilters);
        }
      }}
      sortValue={sort}
      onSortChange={setSort}
      noDataMessage={
        <div>
          <h3 className="text-sm font-semibold text-gray-900">{i18n._('No Payments')}</h3>
        </div>
      }
      pagination={pagination}
      onPaginationChange={onPaginationChange}
      isLoading={isLoading}
    />
  );
};
