import {trpc} from '@/api/trpcClient';
import {RoutePath} from '@/components/layout/navigation';
import {useLocale} from '@/hooks';
import {useStore} from '@/store';
import {Trans, t} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import {
  MerchantAccountDetailsOutput,
  MerchantAccountsListItem,
} from '@zentact/api/src/trpc/routers/merchantAccountRouter';
import {
  CurrencyCode,
  ErrorCode,
  MerchantAccountPublicStatus,
  allIndustryGroups,
  formatAmount,
  formatLocaleDate,
  formatPercentage,
  isFormattedTrpcError,
} from '@zentact/common';
import {
  Breadcrumbs,
  Button,
  FlatPillWithDot,
  InitiateTransferOverlay,
  Loading,
  MerchantApplicationFileDownload,
  MerchantEventsList,
  MerchantPayoutEnableToggle,
  PaymentMethodsPieChart,
  ResumeHopLink,
  Typography,
  getCurrencyCodeTranslation,
  getLocalizedStatusMap,
  merchantAccountStatusToColor,
  useRequiredParams,
  useToggleWithData,
} from '@zentact/ui-tailwind';
import {DateTime} from 'luxon';
import {useState} from 'react';
import {Link, generatePath, useNavigate} from 'react-router-dom';
import {AssignMerchantFeeGroupPanel} from './assign-merchant-fee-group-panel';
import {AssignOrganizationDialog} from './assign-organization-dialog';
import {EditOrganizationName} from './edit-organization-name';
import {InternalTransfersList} from './internal-transfers-list';
import {MerchantActions} from './merchants-list/merchant-actions';
import {
  MerchantReviewPanel,
  MerchantReviewPanelProps,
} from './merchants-list/merchant-review-panel';
import {UpdateMerchantSettlementDelayDays} from './update-merchant-settlement-delay-days';

const getBreadCrumbs = (merchantAccountName?: string) => [
  {name: t`Customers`, href: RoutePath.ORGANIZATIONS, current: false},
  {name: t`Merchants`, href: RoutePath.CUSTOMERS_MERCHANTS, current: false},
  ...(merchantAccountName ? [{name: merchantAccountName, href: '#', current: true}] : []),
];

export const MerchantDetails = () => {
  const navigate = useNavigate();
  const {tenant} = useStore();
  const {i18n} = useLingui();
  const {locale} = useLocale();
  const [isInitiateTransferOverlayOpen, setInitiateTransferOverlayOpen] = useState(false);
  const {merchantAccountId} = useRequiredParams<{merchantAccountId: string}>();
  const [isAssignOrganizationDialogOpen, setAssignOrganizationDialogOpen] = useState(false);

  const merchantAccount = trpc.merchantAccount.getMerchantAccountById.useQuery(
    {merchantAccountId},
    {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
      onError: error => {
        const errorCode = isFormattedTrpcError(error)
          ? error.data.errorCode
          : ErrorCode.ERROR_GENERIC;
        navigate(RoutePath.ERROR, {state: {errorCode}});
      },
    }
  );

  const merchantAccountProfileStats =
    trpc.merchantAccount.getMerchantAccountProfileStatistics.useQuery(
      {
        merchantAccountId,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      {
        keepPreviousData: true,
        onError: error => {
          const errorCode = isFormattedTrpcError(error)
            ? error.data.errorCode
            : ErrorCode.ERROR_GENERIC;
          navigate(RoutePath.ERROR, {state: {errorCode}});
        },
      }
    );

  const merchantAccountBalances = trpc.merchantAccount.getMerchantAccountBalances.useQuery(
    {
      merchantAccountId,
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      initialData: [],
      onError: error => {
        const errorCode = isFormattedTrpcError(error)
          ? error.data.errorCode
          : ErrorCode.ERROR_GENERIC;
        navigate(RoutePath.ERROR, {state: {errorCode}});
      },
    }
  );

  const ongoingTransfers = trpc.internalTransfer.getMerchantOngoingInternalTransfers.useQuery(
    {
      merchantAccountId,
    },
    {
      keepPreviousData: true,
      initialData: {rows: []},
      refetchOnWindowFocus: true,
      refetchInterval: 5000,
    }
  );

  const {
    data: merchantReviewRow,
    on: openMerchantReviewPanel,
    off: closeMerchantReviewPanel,
  } = useToggleWithData<MerchantReviewPanelProps['merchantAccountRow']>();

  const {
    data: updateSettlementDelayDays,
    on: openUpdateSettlementDelayDays,
    off: closeUpdateSettlementDelayDays,
  } = useToggleWithData<MerchantAccountsListItem | MerchantAccountDetailsOutput | null>();

  const {
    data: assignMerchantFeeGroupData,
    on: openAssignMerchantFeeGroupPanel,
    off: closeAssignMerchantFeeGroupPanel,
  } = useToggleWithData<MerchantAccountsListItem | MerchantAccountDetailsOutput | null>();

  if (
    merchantAccount.isLoading ||
    !merchantAccount.data ||
    merchantAccountProfileStats.isLoading ||
    !merchantAccountProfileStats.data ||
    merchantAccountBalances.isLoading
  ) {
    return (
      <div className="flex items-center justify-center h-80">
        <Loading mode="inline" />
      </div>
    );
  }

  const {
    inviteeEmail,
    timeZone,
    uploadedDocumentName,
    sessionId,
    businessMccCode,
    firstName,
    lastName,
    sessionExpiresAt,
  } = merchantAccount.data.merchantAccountRegistration;

  const statusLabelMap = getLocalizedStatusMap(i18n, sessionExpiresAt);

  const {
    storeId,
    storeReferenceId,
    accountHolderId,
    splitConfigurationGroup,
    organization,
    status,
    updatedAt,
    id,
    tenantMerchantReferenceId,
    balanceAccountId,
    onboardedAt,
    businessName,
    settlementDelayDaysOverride,
    legalBusinessName,
  } = merchantAccount.data;

  const applicationDocumentFieldName =
    tenant?.features?.askForDocumentsInMerchantApplication?.title;
  const currency = tenant.currency as CurrencyCode;
  const currentBalance =
    merchantAccountBalances.data.find(b => b.currency === currency)?.available ?? 0;

  return (
    <div className="flex flex-col">
      <Breadcrumbs pages={getBreadCrumbs(businessName)} />
      <div className="flex items-center justify-between my-4">
        <div>
          <Typography variant="header-page" className="flex">
            <Trans>{businessName}</Trans>
          </Typography>
        </div>
        <div className="ml-16">
          <MerchantActions
            merchantAccountRow={merchantAccount.data}
            registrationSessionId={sessionId}
            userId={merchantAccount.data.merchantAccountRegistration.userId}
            inviteeEmail={merchantAccount.data.merchantAccountRegistration.inviteeEmail || ''}
            businessName={businessName}
            refetchOrganizationList={merchantAccount.refetch}
            openReviewPanel={openMerchantReviewPanel}
            openUpdateSettlementDelayDays={openUpdateSettlementDelayDays}
            openAssignMerchantFeeGroupPanel={openAssignMerchantFeeGroupPanel}
            viewType="chevron-button"
          />
        </div>
      </div>
      {!!onboardedAt && (
        <>
          <div className="py-5 mb-5 bg-white rounded-xl md:col-span-2">
            <div className="px-4 mb-5 sm:px-6">
              <Typography variant="header-section" className="mb-2">
                <Trans>Payouts</Trans>
              </Typography>
              <div className="pb-2 text-sm text-gray-600 border-b border-gray-200">
                <Trans>
                  The funds are distributed to merchants every working weekday. Use this toggle to
                  temporarily disable payouts.
                </Trans>
              </div>
            </div>
            <div className="px-4 sm:px-6">
              <MerchantPayoutEnableToggle
                trpc={trpc}
                merchantAccountId={merchantAccount.data.id}
                businessName={merchantAccount.data.businessName}
                payoutsEnabled={merchantAccount.data.payoutsEnabled}
                refetch={merchantAccount.refetch}
              />
            </div>
          </div>
          <div className="grid gap-4 pb-5 max-md:gap-4 md:grid-cols-3">
            <div className="p-5 bg-white shadow rounded-xl md:col-span-1">
              <div className="mb-3 leading-6 text-gray-600 text-medium ">
                <Trans>Last Process Date</Trans>
              </div>
              <div className="text-3xl font-bold text-gray-800">
                {merchantAccountProfileStats.data.lastPaymentDate
                  ? formatLocaleDate(
                      merchantAccountProfileStats.data.lastPaymentDate,
                      DateTime.DATE_MED
                    )
                  : '-'}
              </div>
            </div>
            <div className="p-5 bg-white shadow rounded-xl md:col-span-1">
              <div className="mb-3 leading-6 text-gray-600 text-medium ">
                <Trans>All Time Processed</Trans>
              </div>
              <div className="text-3xl font-bold text-gray-800">
                {formatAmount(merchantAccountProfileStats.data.totalTxVolume, locale, currency)}
              </div>
            </div>
            <div className="p-5 bg-white shadow rounded-xl md:col-span-1">
              <div className="mb-3 leading-6 text-gray-600 text-medium ">
                <Trans>Total Profit</Trans>
              </div>
              <div className="text-3xl font-bold text-gray-800">
                {formatAmount(merchantAccountProfileStats.data.totalProfit, locale, currency)}
              </div>
            </div>
          </div>
        </>
      )}
      {merchantAccountProfileStats.data.totalTxCount > 0 && (
        <div className="py-5 mb-5 bg-white shadow rounded-xl">
          <div className="px-4 pb-5 mb-5 border-b border-gray-200 sm:px-6">
            <Typography variant="header-section">
              <Trans>Transaction Statistics</Trans>
            </Typography>
          </div>
          <div className="grid gap-4 px-4 md:grid-cols-2 sm:px-6">
            <div className="leading-6 border-gray-100 sm:col-span-1">
              <dl className="divide-y divide-gray-100">
                <div className="pb-3 sm:flex">
                  <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                    <Trans>Average Monthly Volume</Trans>
                  </dt>
                  <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">
                    {formatAmount(
                      merchantAccountProfileStats.data.avergeMonthlyVolume,
                      locale,
                      currency
                    )}
                  </dd>
                </div>
                <div className="py-3 sm:flex">
                  <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                    <Trans>Transaction Count</Trans>
                  </dt>
                  <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">
                    {merchantAccountProfileStats.data.totalTxCount}
                  </dd>
                </div>
                <div className="py-3 sm:flex">
                  <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                    <Trans>Average Transaction Amount</Trans>
                  </dt>
                  <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">
                    {formatAmount(
                      merchantAccountProfileStats.data.averageTxAmount,
                      locale,
                      currency
                    )}
                  </dd>
                </div>
                <div className="py-3 sm:flex">
                  <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                    <Trans>Chargeback Count</Trans>
                  </dt>
                  <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">
                    {merchantAccountProfileStats.data.totalChargebackCount}
                  </dd>
                </div>
                <div className="py-3 sm:flex">
                  <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                    <Trans>Chargeback Volume</Trans>
                  </dt>
                  <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">
                    {formatAmount(
                      merchantAccountProfileStats.data.totalChargebackVolume,
                      locale,
                      currency
                    )}
                  </dd>
                </div>
                <div className="py-3 sm:flex">
                  <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                    <Trans>Average Chargeback Amount</Trans>
                  </dt>
                  <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">
                    {formatAmount(
                      merchantAccountProfileStats.data.averageChargebackAmount,
                      locale,
                      currency
                    )}
                  </dd>
                </div>
                <div className="py-3 sm:flex">
                  <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                    <Trans>Chargeback Percentage</Trans>
                  </dt>
                  <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">
                    {formatPercentage(
                      merchantAccountProfileStats.data.totalTxCount,
                      merchantAccountProfileStats.data.totalChargebackCount,
                      locale
                    )}
                  </dd>
                </div>
              </dl>
            </div>
            <div className="md:col-span-1">
              <PaymentMethodsPieChart
                paymentMethods={merchantAccountProfileStats.data.paymentMethodStats}
                locale={locale}
              />
            </div>
          </div>
        </div>
      )}
      {merchantAccountBalances.data.length > 0 && (
        <div className="py-5 mb-5 bg-white shadow rounded-xl md:col-span-2">
          <div className="flex justify-between px-4 pb-5 mb-2 border-b border-gray-200 sm:px-6">
            <Typography variant="header-section">
              <Trans>Balances</Trans>
            </Typography>
            {status === MerchantAccountPublicStatus.ACTIVE && (
              <Button
                variant="primary"
                size="lg"
                className="w-fit"
                onClick={() => setInitiateTransferOverlayOpen(true)}
              >
                <Trans>Initiate Transfer</Trans>
              </Button>
            )}
          </div>
          <div className="px-4 leading-6 divide-y divide-gray-100 sm:px-6">
            {merchantAccountBalances.data.map((balance, index) => (
              <div key={`balance-${index}`} className="py-3">
                <dt className="pb-1 text-sm font-medium text-gray-900">
                  {getCurrencyCodeTranslation(balance.currency, i18n)}
                </dt>
                <div className="px-2">
                  <div className="sm:flex max-sm:mt-1">
                    <dt className="text-sm font-medium text-gray-900 sm:w-[192px] sm:mr-6">
                      {balance.currency} <Trans>Balance</Trans>
                    </dt>
                    <dd className="text-sm text-gray-700 break-words sm:mt-0">
                      {formatAmount(balance.balance, locale, balance.currency)}
                    </dd>
                  </div>
                  <div className="sm:flex max-sm:mt-1">
                    <dt className="text-sm font-medium text-gray-900 sm:w-[192px] sm:mr-6">
                      {balance.currency} <Trans>Pending</Trans>
                    </dt>
                    <dd className="text-sm text-gray-700 break-words sm:mt-0">
                      {formatAmount(balance.pending, locale, balance.currency)}
                    </dd>
                  </div>
                  <div className="sm:flex max-sm:mt-1">
                    <dt className="text-sm font-medium text-gray-900 sm:w-[192px] sm:mr-6">
                      {balance.currency} <Trans>Reserved</Trans>
                    </dt>
                    <dd className="text-sm text-gray-700 break-words sm:mt-0">
                      {formatAmount(balance.reserved, locale, balance.currency)}
                    </dd>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {ongoingTransfers.data.rows?.length > 0 && (
        <div className="pt-5 mb-5 bg-white shadow lg:pb-5 rounded-xl md:col-span-2">
          <div className="flex px-4 pb-5 mb-4 border-b border-gray-200 sm:px-6">
            <Typography variant="header-section">
              <Trans>Ongoing Transfers</Trans>
            </Typography>{' '}
          </div>
          <div className="leading-6 lg:px-6">
            <InternalTransfersList
              list={ongoingTransfers.data}
              isLoading={ongoingTransfers.isLoading}
              refetch={() => {
                ongoingTransfers.refetch();
              }}
            />
          </div>
        </div>
      )}
      <div className="py-5 mb-5 bg-white shadow rounded-xl md:col-span-2">
        <div className="px-4 pb-5 mb-5 border-b border-gray-200 sm:px-6">
          <Typography variant="header-section">
            <Trans>General</Trans>
          </Typography>
        </div>
        <div className="px-4 sm:px-6">
          <div className="leading-6 border-gray-100 ">
            <dl className="divide-y divide-gray-100">
              {!!legalBusinessName && (
                <div className="pb-3 sm:flex">
                  <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                    <Trans>Legal Business Name</Trans>
                  </dt>
                  <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">
                    {legalBusinessName}
                  </dd>
                </div>
              )}
              <div className="py-3 sm:flex sm:items-center">
                <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                  <Trans>Organization</Trans>
                </dt>
                <dd className="flex items-center text-sm text-gray-700 break-words">
                  <div className="flex items-center gap-x-2">
                    {!organization.notAssigned ? (
                      <>
                        <Link
                          to={`${RoutePath.CUSTOMERS_MERCHANTS}?organizationId=${organization.id}`}
                          className="text-primary-600 hover:underline"
                        >
                          {organization.name}
                        </Link>
                        <EditOrganizationName
                          organizationName={organization.name}
                          organizationId={organization.id}
                          refetch={merchantAccount.refetch}
                        />
                      </>
                    ) : (
                      <>
                        <p className="text-sm italic text-gray-500">
                          <Trans>Not Assigned</Trans>
                        </p>
                        <Button
                          variant="primary"
                          size="lg"
                          className="w-fit"
                          onClick={() => setAssignOrganizationDialogOpen(true)}
                        >
                          <Trans>Assign Organization</Trans>
                        </Button>
                      </>
                    )}
                  </div>
                </dd>
              </div>

              {firstName && lastName && (
                <div className="py-3 sm:flex">
                  <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                    <Trans>Owner</Trans>
                  </dt>
                  <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">{`${firstName} ${lastName}`}</dd>
                </div>
              )}
              {inviteeEmail && (
                <div className="py-3 sm:flex">
                  <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                    <Trans>Contact Email</Trans>
                  </dt>
                  <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">{inviteeEmail}</dd>
                </div>
              )}
              <div className="py-3 sm:flex">
                <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                  <Trans>Settlement Delay</Trans>
                </dt>
                <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">
                  <Trans>T+</Trans>
                  {settlementDelayDaysOverride || tenant?.settlementDelayDays}
                </dd>
              </div>
              {!!status && (
                <div className="py-3 sm:flex">
                  <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                    <Trans>Status</Trans>
                  </dt>
                  <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">
                    <div className="flex items-center gap-x-2">
                      <FlatPillWithDot
                        color={merchantAccountStatusToColor[status] || 'blue'}
                        label={statusLabelMap[status]}
                      />
                      {merchantAccount.data.isApplicationSubmitted && (
                        <ResumeHopLink registrationSessionId={sessionId} trpc={trpc} />
                      )}
                    </div>
                  </dd>
                </div>
              )}
              {!!timeZone && (
                <div className="py-3 sm:flex">
                  <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                    <Trans>Account Time Zone</Trans>
                  </dt>
                  <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">{timeZone}</dd>
                </div>
              )}
              {!!splitConfigurationGroup && (
                <div className="py-3 sm:flex">
                  <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                    <Trans>Fee Group</Trans>
                  </dt>
                  <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">
                    <Link
                      to={generatePath(RoutePath.FEE_GROUP_DETAILS, {
                        splitConfigurationGroupId: splitConfigurationGroup.id,
                      })}
                      className="text-primary-600 hover:underline"
                    >
                      {splitConfigurationGroup.name}
                    </Link>
                  </dd>
                </div>
              )}
              {!!businessMccCode && (
                <div className="py-3 sm:flex">
                  <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                    <Trans>Industry</Trans>
                  </dt>
                  <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">
                    {`${
                      allIndustryGroups[businessMccCode as keyof typeof allIndustryGroups] ||
                      t`Unrecognized`
                    } (${businessMccCode})`}
                  </dd>
                </div>
              )}
              {!!applicationDocumentFieldName && !!uploadedDocumentName && (
                <div className="py-3 sm:flex">
                  <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                    {applicationDocumentFieldName}
                  </dt>
                  <dd className="flex items-center mt-1 text-sm text-gray-700 break-words sm:mt-0">
                    <div className="flex items-center gap-x-4">
                      {uploadedDocumentName}
                      <MerchantApplicationFileDownload
                        registrationSessionId={sessionId}
                        trpc={trpc}
                      />
                    </div>
                  </dd>
                </div>
              )}
              {!!onboardedAt && (
                <div className="py-3 sm:flex">
                  <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                    <Trans>Onboarded At</Trans>
                  </dt>
                  <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">
                    {formatLocaleDate(onboardedAt)}
                  </dd>
                </div>
              )}
              <div className="py-3 sm:flex">
                <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                  <Trans>Last Updated At</Trans>
                </dt>
                <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">
                  {formatLocaleDate(updatedAt)}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
      <div className="py-5 mb-5 bg-white shadow rounded-xl md:col-span-2">
        <div className="px-4 pb-5 mb-5 border-b border-gray-200 sm:px-6">
          <Typography variant="header-section">
            <Trans>Technical Information</Trans>
          </Typography>
        </div>
        <div className="px-4 sm:px-6">
          <div className="leading-6 border-gray-100 ">
            <dl className="divide-y divide-gray-100">
              <div className="pb-3 sm:flex">
                <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                  <Trans>Merchant Account ID</Trans>
                </dt>
                <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">{id}</dd>
              </div>
              {!!tenantMerchantReferenceId && (
                <div className="py-3 sm:flex">
                  <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                    <Trans>Merchant Reference ID</Trans>
                  </dt>
                  <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">
                    {tenantMerchantReferenceId}
                  </dd>
                </div>
              )}
              {!!storeId && (
                <div className="py-3 sm:flex">
                  <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                    <Trans>Adyen Store ID</Trans>
                  </dt>
                  <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">{storeId}</dd>
                </div>
              )}
              {!!storeReferenceId && (
                <div className="py-3 sm:flex">
                  <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                    <Trans>Store Reference ID</Trans>
                  </dt>
                  <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">
                    {storeReferenceId}
                  </dd>
                </div>
              )}
              {!!balanceAccountId && (
                <div className="py-3 sm:flex">
                  <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                    <Trans>Adyen Balance Account ID</Trans>
                  </dt>
                  <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">
                    {balanceAccountId}
                  </dd>
                </div>
              )}
              {!!accountHolderId && (
                <div className="py-3 sm:flex">
                  <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                    <Trans>Adyen Account Holder ID</Trans>
                  </dt>
                  <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">
                    {accountHolderId}
                  </dd>
                </div>
              )}
              {!!splitConfigurationGroup?.adyenSplitConfigurationId && (
                <div className="py-3 sm:flex">
                  <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                    <Trans>Adyen Split Configuration ID</Trans>
                  </dt>
                  <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">
                    {splitConfigurationGroup.adyenSplitConfigurationId}
                  </dd>
                </div>
              )}
            </dl>
          </div>
        </div>
      </div>
      <div className="py-5 mb-5 bg-white shadow rounded-xl md:col-span-2">
        <div className="px-4 pb-5 mb-5 border-b border-gray-200 sm:px-6">
          <Typography variant="header-section">
            <Trans>Account History</Trans>
          </Typography>
        </div>
        <div className="px-4 sm:px-6">
          <MerchantEventsList
            merchantAccount={merchantAccount.data}
            feeGroupListPath={RoutePath.FEE_GROUPS}
            paymentDetailsPath={RoutePath.PAYMENT_DETAILS}
            merchantsPath={RoutePath.CUSTOMERS_MERCHANTS}
          />
        </div>
      </div>
      <MerchantReviewPanel
        isOpen={!!merchantReviewRow}
        onCancel={closeMerchantReviewPanel}
        merchantAccountRow={merchantReviewRow}
        onSubmit={() => merchantAccount.refetch()}
      />
      <InitiateTransferOverlay
        open={isInitiateTransferOverlayOpen}
        setOpen={setInitiateTransferOverlayOpen}
        merchantAccount={merchantAccount.data}
        i18n={i18n}
        trpc={trpc}
        currency={currency}
        currentBalance={currentBalance}
        onSuccess={() => {
          merchantAccountBalances.refetch();
          ongoingTransfers.refetch();
        }}
        tenantName={tenant?.name ?? ''}
      />
      {updateSettlementDelayDays && (
        <UpdateMerchantSettlementDelayDays
          merchantAccountRow={updateSettlementDelayDays}
          onClose={closeUpdateSettlementDelayDays}
          isOpen={!!openUpdateSettlementDelayDays}
          triggerRefetch={merchantAccount.refetch}
        />
      )}
      {assignMerchantFeeGroupData && (
        <AssignMerchantFeeGroupPanel
          merchantAccountRow={assignMerchantFeeGroupData}
          onClose={closeAssignMerchantFeeGroupPanel}
          isOpen={!!assignMerchantFeeGroupData}
          triggerRefetch={merchantAccount.refetch}
        />
      )}
      {merchantAccount.data && (
        <AssignOrganizationDialog
          isOpen={isAssignOrganizationDialogOpen}
          setOpen={setAssignOrganizationDialogOpen}
          merchantAccountId={merchantAccountId}
          businessName={businessName}
          triggerRefetch={merchantAccount.refetch}
        />
      )}
    </div>
  );
};
