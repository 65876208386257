import {trpc} from '@/api/trpcClient';
import {useStore} from '@/store';
import {zodResolver} from '@hookform/resolvers/zod';
import {Trans, t} from '@lingui/macro';
import {
  AlertOverlayWithConfirmation,
  InputSearchSelect,
  ValidationError,
  useNotification,
} from '@zentact/ui-tailwind';
import {Dispatch, SetStateAction, useCallback, useMemo} from 'react';
import {Controller, useForm} from 'react-hook-form';
import * as z from 'zod';

type Props = {
  isOpen: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  merchantAccountId: string;
  businessName: string;
  triggerRefetch: () => void;
};

const assignOrganizationSchema = () =>
  z.object({
    // biome-ignore lint/style/useNamingConvention: <explanation>
    organizationId: z.string({required_error: t`Organization is not selected`}),
  });
type AssignOrganizationlData = z.infer<ReturnType<typeof assignOrganizationSchema>>;

export const AssignOrganizationDialog = ({
  isOpen,
  setOpen,
  merchantAccountId,
  businessName,
  triggerRefetch,
}: Props) => {
  const {organizationList} = useStore();
  const {showSuccessNotification, showErrorNotification} = useNotification();

  const organizationOptions = useMemo(
    () =>
      organizationList
        .filter(org => !org.notAssigned)
        .map(({id, name}) => ({
          id,
          label: name,
        })),
    [organizationList]
  );

  const {
    control,
    handleSubmit,
    formState: {errors},
  } = useForm<AssignOrganizationlData>({
    resolver: zodResolver(assignOrganizationSchema()),
  });

  const assignOrganizationMutation = trpc.organization.assignOrganization.useMutation({
    onSuccess: () => {
      triggerRefetch();
      setOpen(false);
      showSuccessNotification(
        t`The organization assigned`,
        t`You have successfully assigned the organization to the merchant.`
      );
    },
    onError: error => {
      showErrorNotification(t`Error`, error.message);
    },
  });

  const onSubmit = useCallback(
    ({organizationId}: AssignOrganizationlData) => {
      assignOrganizationMutation.mutate({
        merchantAccountId: merchantAccountId,
        organizationId,
      });
    },
    [merchantAccountId]
  );

  return (
    <AlertOverlayWithConfirmation
      open={isOpen}
      setOpen={setOpen}
      handleAction={handleSubmit(onSubmit)}
      localeText={{
        title: t`Assign Organization`,
        description: (
          <div>
            <Trans>
              You are assigning an organization for {businessName}.{' '}
              <div className="font-semibold">
                Note: The organization cannot be changed once it has been assigned.
              </div>
            </Trans>
          </div>
        ),
        confirm: t`Confirm`,
        cancel: t`Cancel`,
      }}
      mode="error"
      buttonMode="success"
      loading={assignOrganizationMutation.isLoading}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mx-4 mb-4 sm:mx-20 sm:w-96">
          <Controller
            name="organizationId"
            control={control}
            render={({field}) => (
              <InputSearchSelect
                {...field}
                className=""
                options={organizationOptions}
                inSidePanel
                placeholder={t`Please select organization`}
              />
            )}
          />
        </div>

        <ValidationError isVisible={Boolean(errors.organizationId)}>
          {errors.organizationId?.message}
        </ValidationError>
      </form>
    </AlertOverlayWithConfirmation>
  );
};
