import {useAuth0} from '@auth0/auth0-react';
import {Trans} from '@lingui/macro';

export const LogoutPage = () => {
  const {loginWithRedirect, isAuthenticated, logout} = useAuth0();

  if (isAuthenticated) {
    logout({logoutParams: {returnTo: `${window.location.origin}/logout`}});
    return;
  }

  return (
    <section className="bg-white dark:bg-gray-900 ">
      <div className="container mx-auto flex min-h-screen items-center px-6 py-12">
        <div>
          <h1 className="mt-3 text-2xl font-semibold text-gray-800 md:text-3xl dark:text-white">
            <Trans>You have been logged out.</Trans>
          </h1>
          <div className="mt-6 flex items-center gap-x-3">
            <button
              type="button"
              onClick={() => loginWithRedirect({appState: {returnTo: '/'}})}
              className="bg-primary-500 hover:bg-primary-600 dark:bg-primary-600 dark:hover:bg-primary-500 w-1/2 shrink-0 rounded-lg px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 sm:w-auto"
            >
              <Trans>Re-Login</Trans>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};
