import {trpc} from '@/api/trpcClient';
import {RoutePath} from '@/components/layout/navigation';
import {Trans, t} from '@lingui/macro';
import {TenantWebhookListItemOutput} from '@zentact/api/src/trpc/routers/webhookRouter';
import {webhookSchema} from '@zentact/common';
import {
  Breadcrumbs,
  Button,
  TableSortValue,
  Typography,
  useDrawerState,
  useToggleWithData,
  useTypedSearchParams,
} from '@zentact/ui-tailwind';
import {useEffect, useState} from 'react';
import {AddWebhook} from './add-webhook';
import {EditWebhook} from './edit-webhook';
import {WebhookList} from './webhooks-list';

const breadcrumbs = () => [
  {name: t`Configure`, href: RoutePath.CONFIGURE, current: false, disabled: true},
  {name: t`Webhooks`, href: '#', current: true},
];

export const Webhooks = () => {
  const [sort, setSort] = useState<TableSortValue<string> | null>(null);
  const [pagination, setPagination] = useState({pageIndex: 0, pageSize: 15});

  const {typedSearchParams, setTypedSearchParams} = useTypedSearchParams(webhookSchema);

  const name = typedSearchParams?.name;

  const webhookList = trpc.webhooks.getTenantWebhookList.useQuery(
    {
      ...pagination,
      where: {
        ...(name && {name}),
      },
    },
    {refetchOnWindowFocus: true, keepPreviousData: true}
  );

  useEffect(() => {
    setPagination(prev => ({...prev, pageIndex: 0}));
  }, [typedSearchParams, sort]);

  const {
    isOpen: isAddApiKeyFormVisible,
    open: openAddApiKeyForm,
    close: closeAddApiKeyForm,
    onSuccess: onAddApiKeySuccess,
  } = useDrawerState({
    onSuccessHandler: () => {
      webhookList.refetch();
    },
  });

  const {
    data: editRow,
    on: openEditPanel,
    off: closeEditPanel,
  } = useToggleWithData<TenantWebhookListItemOutput>();

  return (
    <div className="flex flex-col">
      <Breadcrumbs pages={breadcrumbs()} />
      <div className="flex justify-between gap-2 py-4 max-sm:flex-col sm:items-center">
        <Typography variant="header-page" className="flex">
          <Trans>Webhooks</Trans>
        </Typography>
        <Button
          type="button"
          variant="primary"
          size="md"
          onClick={openAddApiKeyForm}
          className="w-fit max-sm:w-full"
        >
          <Trans>Add New</Trans>
        </Button>
      </div>
      <div className="mt-4">
        <WebhookList
          webhookDataList={webhookList.data}
          refetch={webhookList.refetch}
          sort={sort}
          setSort={setSort}
          filters={typedSearchParams}
          setFilters={setTypedSearchParams}
          pagination={pagination}
          onPaginationChange={setPagination}
          openEditPanel={openEditPanel}
          isLoading={
            webhookList.isLoading || (webhookList.isRefetching && webhookList.isPreviousData)
          }
        />
      </div>
      <AddWebhook
        isOpen={isAddApiKeyFormVisible}
        onCancel={closeAddApiKeyForm}
        onSuccess={onAddApiKeySuccess}
      />
      {editRow && (
        <EditWebhook
          row={editRow}
          onClose={closeEditPanel}
          refetch={webhookList.refetch}
          isOpen={!!editRow}
        />
      )}
    </div>
  );
};
