import {createTRPCReact, httpBatchLink} from '@trpc/react-query';
import {type ServerRouter} from '@zentact/api';

export const trpc = createTRPCReact<ServerRouter>();

export let accessToken: string;

export const setAccessToken = (newToken: string) => {
  accessToken = newToken;
};

export const trpcClient = trpc.createClient({
  links: [
    httpBatchLink({
      url: import.meta.env.VITE_TRPC_URL,
      headers: () => {
        if (!accessToken) {
          return {};
        }
        return {
          authorization: `Bearer ${accessToken}`,
        };
      },
      fetch(url, options) {
        return new Promise((resolve, reject) => {
          const timer = setTimeout(() => reject(new Error('Request timed out')), 60000);
          fetch(url, options).then(
            response => {
              clearTimeout(timer);
              resolve(response);
            },
            err => {
              clearTimeout(timer);
              reject(err);
            }
          );
        });
      },
    }),
  ],
});
