import {Trans} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import {
  OrganizationListItem,
  OrganizationListOutput,
} from '@zentact/api/src/trpc/routers/organizationRouter';
import {TableSortValue, TableWrapper} from '@zentact/ui-tailwind';
import {Dispatch, SetStateAction} from 'react';
import {getOrganizationListColumns} from './columns';

type Props<TFilters extends Record<string, unknown>> = {
  organizationList?: OrganizationListOutput;
  filters?: TFilters;
  setFilters?: (params: TFilters) => void;
  sort: TableSortValue<string> | null;
  setSort: Dispatch<SetStateAction<TableSortValue<string> | null>>;
  pagination?: {pageIndex: number; pageSize: number};
  onPaginationChange?: Dispatch<SetStateAction<{pageIndex: number; pageSize: number}>>;
  refetch: () => void;
  isLoading?: boolean;
  openDetailsPanel: (row: OrganizationListItem) => void;
};

export const OrganizationsList = <TFilters extends Record<string, unknown>>({
  organizationList,
  filters,
  setFilters,
  sort,
  setSort,
  pagination,
  onPaginationChange,
  refetch,
  isLoading,
  openDetailsPanel,
}: Props<TFilters>) => {
  const {i18n} = useLingui();
  return (
    <TableWrapper
      data={organizationList?.rows ?? []}
      pageCount={organizationList?.pagination.pageCount ?? 0}
      columns={getOrganizationListColumns({
        refetchOrganizationList: refetch,
        i18n,
        openDetailsPanel,
      })}
      filterValues={filters}
      onFilterChange={({id, value}) => {
        if (setFilters) {
          setFilters({[id]: value} as TFilters);
        }
      }}
      sortValue={sort}
      onSortChange={setSort}
      noDataMessage={
        <div>
          <h3 className="text-sm font-semibold text-gray-900">
            <Trans>No Organizations</Trans>
          </h3>
        </div>
      }
      onRowClick={openDetailsPanel}
      pagination={pagination}
      onPaginationChange={onPaginationChange}
      excludeLastColumnFromRowClick
      isLoading={isLoading}
    />
  );
};
