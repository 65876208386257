import {Trans} from '@lingui/macro';
import {
  TenantWebhookListItemOutput,
  TenantWebhookListOutput,
} from '@zentact/api/src/trpc/routers/webhookRouter';
import {TableSortValue, TableWrapper} from '@zentact/ui-tailwind';
import {Dispatch, SetStateAction} from 'react';
import {getWebhooksColumns} from './columns';

type Filters = {
  name?: string;
};

type Props<TFilters extends Filters> = {
  webhookDataList?: TenantWebhookListOutput;
  filters?: TFilters;
  setFilters?: (params: TFilters) => void;
  sort: TableSortValue<string> | null;
  setSort: Dispatch<SetStateAction<TableSortValue<string> | null>>;
  pagination?: {pageIndex: number; pageSize: number};
  onPaginationChange?: Dispatch<SetStateAction<{pageIndex: number; pageSize: number}>>;
  refetch: () => void;
  isLoading?: boolean;
  openEditPanel: (row: TenantWebhookListItemOutput) => void;
};

export const WebhookList = <TFilters extends Record<string, unknown>>({
  webhookDataList,
  filters,
  setFilters,
  sort,
  setSort,
  pagination,
  onPaginationChange,
  isLoading,
  refetch,
  openEditPanel,
}: Props<TFilters>) => {
  return (
    <TableWrapper
      data={webhookDataList?.rows ?? []}
      columns={getWebhooksColumns({openEditPanel, refetch})}
      filterValues={filters}
      onFilterChange={({id, value}) => {
        if (setFilters) {
          setFilters({[id]: value} as TFilters);
        }
      }}
      onRowClick={row => openEditPanel(row)}
      sortValue={sort}
      onSortChange={setSort}
      noDataMessage={
        <div>
          <h3 className="text-sm font-semibold text-gray-900">
            <Trans>No Webhooks</Trans>
          </h3>
        </div>
      }
      pagination={pagination}
      onPaginationChange={onPaginationChange}
      excludeLastColumnFromRowClick
      isLoading={isLoading}
    />
  );
};
