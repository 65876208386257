import {Typography} from '../typography';

type SectionProps = {
  title: React.ReactNode;
  subTitle?: React.ReactNode;
  children: React.ReactNode;
};

export const Section = ({children, title, subTitle}: SectionProps) => {
  return (
    <div className="mt-4 bg-white divide-y divide-gray-200 rounded-lg shadow">
      <div className="px-4 py-5 sm:px-6">
        <Typography variant="header-form-section">{title}</Typography>
        {subTitle && <div className="text-sm text-gray-500">{subTitle}</div>}
      </div>
      <div className="px-4 pt-3 pb-5 sm:p-6 sm:pt-6 sm:pb-7">{children}</div>
    </div>
  );
};
