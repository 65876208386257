import {DateTime} from 'luxon';
import * as z from 'zod';

export const otherRevenueSearchParamsSchema = z.object({
  organizationId: z.string().nullable().optional(),
  merchantAccountId: z.string().nullable().optional(),
  startDate: z
    .date()
    .or(z.string().transform(value => DateTime.fromFormat(value, 'y-MM-dd').toJSDate()))
    .nullable()
    .optional(),
  endDate: z
    .date()
    .or(
      z.string().transform(value => DateTime.fromFormat(value, 'y-MM-dd').endOf('day').toJSDate())
    )
    .nullable()
    .optional(),
  sortColumnId: z.string().nullable().optional(),
  sortValue: z.literal('asc').or(z.literal('desc')).nullable().optional(),
  type: z
    .array(z.string())
    .or(z.string().transform(str => str.split(',')))
    .optional(),
  pageIndex: z.string().optional(),
});

export type OtherRevenueSearchParamsSchema = z.infer<typeof otherRevenueSearchParamsSchema>;
