import {trpc} from '@/api/trpcClient';
import {RoutePath} from '@/components/layout/navigation';
import {useStore} from '@/store';
import {ChevronDownIcon} from '@heroicons/react/20/solid';
import {Trans, t} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import {OnChangeFn, PaginationState} from '@tanstack/react-table';
import {PaymentListItemOutput} from '@zentact/api/src/trpc/routers/paymentRouter';
import {formatLocaleDate, paymentsSchema} from '@zentact/common';
import {tailwindDefaultBreakpoints} from '@zentact/tailwind-config/tailwind-default-breakpoints';
import {
  Breadcrumbs,
  Button,
  PaymentDetailsPanel,
  PaymentFilters,
  PaymentsList,
  ResetTableFiltersButton,
  SlideOverWithBrandedHeader,
  TableListSort,
  TableSortValue,
  Typography,
  fetchFullListFromPaginatedEndpoint,
  getDashboardDatepickerDefaults,
  useNotification,
  useToggle,
  useTypedSearchParams,
} from '@zentact/ui-tailwind';
import {useCallback, useEffect, useState} from 'react';
import {generatePath, useNavigate} from 'react-router-dom';
import {useMediaQuery} from 'usehooks-ts';
import {z} from 'zod';
import {exportToCsvPayments} from './csv-export';

const getBreadCrumbs = () => [
  {name: t`Transactions`, href: RoutePath.TRANSACTIONS, current: false},
  {name: t`Payments`, href: '#', current: true},
];

export const Payments = () => {
  const {showSuccessNotification, showErrorNotification} = useNotification();
  const {
    orgsWithBoardedMerchants: organizationList,
    activeAndInactiveMerchantAccounts: merchantAccounts,
  } = useStore();
  const navigate = useNavigate();
  const isDesktop = useMediaQuery(`(min-width: ${tailwindDefaultBreakpoints.lg})`);
  const {tenant} = useStore();
  const customAttributesNames = tenant.checkoutConfiguration?.customAttributesNames;

  const adminPaymentsSchema = z.intersection(
    paymentsSchema,
    z.object({
      // These don't come out as type but are still parsed
      ...Object.fromEntries(
        customAttributesNames?.map(name => [
          name,
          z
            .string()
            .or(z.date().or(z.number()).or(z.array(z.string())))
            .optional()
            .nullable(),
        ]) || []
      ),
    })
  );

  type TypedSearchParamsSchema = z.infer<typeof adminPaymentsSchema>;

  const {typedSearchParams, setTypedSearchParams} = useTypedSearchParams(adminPaymentsSchema);

  const selectedMerchantAccount = typedSearchParams.selectedMerchantAccount;
  const status = typedSearchParams?.status;
  const pspReferenceId = typedSearchParams?.pspReferenceId;
  const paymentMethod = typedSearchParams?.paymentMethod;
  const source = typedSearchParams?.source;
  const shopperEmail = typedSearchParams?.shopperEmail;
  const selectedOrganization = typedSearchParams?.selectedOrganization;
  const startDate = typedSearchParams?.startDate;
  const endDate = typedSearchParams?.endDate;
  const dateRangeFilterBy = typedSearchParams.dateRangeFilterBy ?? 'transaction';
  const dateValue = startDate && endDate ? {startDate, endDate} : getDashboardDatepickerDefaults();
  const pageIndex = typedSearchParams?.pageIndex;
  const pageSize = typedSearchParams?.pageSize;

  const pagination = {
    pageIndex: pageIndex || 0,
    pageSize: pageSize || 25,
  };

  const customFilterValues: {[key: string]: string} = {};
  for (const attr of customAttributesNames ?? []) {
    const value = typedSearchParams[attr as keyof TypedSearchParamsSchema];
    if (typeof value === 'string') {
      customFilterValues[attr] = value;
    }
  }

  const [sort, setSort] = useState<TableSortValue<string>>({columnId: 'createdAt', value: 'desc'});

  const {
    data: {filters: paymentMethodsFilters, filtersDescription: paymentMethodsFiltersDescription},
  } = trpc.payment.paymentMethods.useQuery(undefined, {
    keepPreviousData: true,
    refetchOnWindowFocus: true,
    initialData: {list: [], filters: [], filtersDescription: {}},
  });

  const paymentsFilters = {
    ...(sort?.columnId && sort.value && {orderBy: {[sort.columnId]: sort.value}}),
    where: {
      ...(status?.length && {status}),
      ...(pspReferenceId && {pspReferenceId}),
      ...(dateValue &&
        dateRangeFilterBy === 'transaction' && {
          fromDate: dateValue.startDate.toISOString(),
          toDate: dateValue.endDate.toISOString(),
        }),
      ...(paymentMethod?.length && {
        paymentMethod: paymentMethod.flatMap(
          // biome-ignore lint/style/noNonNullAssertion: TODO
          filter => paymentMethodsFiltersDescription[filter]!
        ),
      }),
      ...(customFilterValues && {
        customAttributes: customFilterValues,
      }),
      ...(source?.length && {source}),
      ...(selectedOrganization && {organizationIds: [selectedOrganization]}),
      ...(shopperEmail && {shopperEmail}),
      ...(dateValue &&
        dateRangeFilterBy === 'refund' && {
          refundEventFromDate: dateValue.startDate.toISOString(),
          refundEventToDate: dateValue.endDate.toISOString(),
        }),
      ...(dateValue &&
        dateRangeFilterBy === 'chargeback' && {
          chargebackEventFromDate: dateValue.startDate.toISOString(),
          chargebackEventToDate: dateValue.endDate.toISOString(),
        }),
      ...(selectedMerchantAccount?.length && {merchantAccountId: selectedMerchantAccount}),
    },
  };

  const paymentList = trpc.payment.payments.useQuery(
    {
      ...paymentsFilters,
      ...pagination,
    },
    {keepPreviousData: true, refetchOnWindowFocus: true}
  );
  const trpcContext = trpc.useUtils();

  const [isSidePanelOpen, openSidePanel, closeSidePanel] = useToggle(false);
  const [paymentDetailsRow, setPaymentDetailsRow] = useState<PaymentListItemOutput | null>(null);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const handleOpenPaymentDetailsPage = useCallback(
    (pspReferenceId: string) => {
      navigate(generatePath(RoutePath.PAYMENT_DETAILS, {pspReferenceId}));
    },
    [navigate]
  );

  const handleOpenSidePanel = useCallback(
    (row: PaymentListItemOutput) => {
      if (isDesktop) {
        handleOpenPaymentDetailsPage(row.pspReferenceId);
      } else {
        setPaymentDetailsRow(row);
        openSidePanel();
      }
    },
    [openSidePanel, setPaymentDetailsRow, handleOpenPaymentDetailsPage, isDesktop]
  );

  const {i18n} = useLingui();

  const [isCsvLoading, setCsvLoading] = useState(false);
  const handleCsvExport = useCallback(async () => {
    setCsvLoading(true);
    try {
      const fullPaymentList = await fetchFullListFromPaginatedEndpoint(
        trpcContext.payment.payments,
        paymentsFilters
      );
      exportToCsvPayments(fullPaymentList.rows, i18n);
      showSuccessNotification(t`Payments .csv file exported`);
    } catch (e) {
      showErrorNotification(t`Payments .csv export failed`, (e as Error).message);
    }
    setCsvLoading(false);
  }, [setCsvLoading, paymentsFilters, trpcContext, exportToCsvPayments]);

  useEffect(() => {
    if (
      paymentList.data?.pagination.pageCount &&
      pagination.pageIndex > paymentList.data.pagination.pageCount - 1
    ) {
      setTypedSearchParams({
        pageIndex: paymentList.data.pagination.pageCount - 1,
      });
    }
  }, [pagination, paymentList]);

  const handleDateChange = useCallback(
    (dateRange: {startDate: Date; endDate: Date}) => {
      setTypedSearchParams({
        ...dateRange,
        pageIndex: undefined,
        pageSize: undefined,
        dateRangeFilterBy: undefined,
      });
    },
    [setTypedSearchParams]
  );

  const onPaginationChange = useCallback<OnChangeFn<PaginationState>>(
    updaterOrValue => {
      let newPaginationState: PaginationState;

      if (typeof updaterOrValue === 'function') {
        newPaginationState = updaterOrValue(pagination);
      } else {
        newPaginationState = updaterOrValue;
      }

      setTypedSearchParams({...typedSearchParams, ...newPaginationState});
    },
    [pagination, setTypedSearchParams, typedSearchParams]
  );
  return (
    <div className="flex flex-col">
      <Breadcrumbs pages={getBreadCrumbs()} />
      <div className="flex justify-between gap-2 pt-4 max-sm:flex-col sm:items-center">
        <Typography variant="header-page" className="flex">
          <Trans>Payments For</Trans> {formatLocaleDate(dateValue.startDate, 'short')}
          {' - '}
          {formatLocaleDate(dateValue.endDate, 'short')}
        </Typography>
        <Button
          type="button"
          variant="primary"
          size="md"
          className="w-fit max-sm:w-full"
          isLoading={isCsvLoading}
          onClick={handleCsvExport}
          disabled={!paymentList.data || paymentList.data.rows.length === 0}
        >
          <Trans>Export to CSV</Trans>
        </Button>
      </div>

      <div className="flex justify-between gap-2 mt-4 font-normal max-sm:flex-wrap sm:items-center">
        <TableListSort
          options={[
            {
              id: 'createdAt',
              label: i18n._('Date'),
            },
            {
              id: 'authorizedAmount',
              label: i18n._('Amount'),
            },
          ]}
          sort={sort}
          setSort={setSort}
        />
        <div className="flex justify-between gap-2">
          <div className="lg:hidden">
            <ResetTableFiltersButton
              ignoreKeys={[
                'endDate', // ignore endDate to show date range as a single applied date filter
                'pageIndex',
                'pageSize',
              ]}
              defaultFilters={{}}
              activeFilters={typedSearchParams}
              setFilters={setTypedSearchParams}
            />
          </div>
          <Button
            type="button"
            size="sm"
            onClick={() => setIsFiltersOpen(true)}
            className="lg:hidden w-fit relative min-h-[2.25rem] shadow-none cursor-pointer font-semibold rounded-md py-1.5 pl-3 pr-10 text-left focus:outline-none text-gray-700 disabled:bg-slate-100 bg-transparent hover:bg-transparent focus:bg-transparent active:bg-transparent sm:text-sm sm:leading-6 border-none"
          >
            <Trans>Filter</Trans>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 ml-3 pointer-events-none">
              <ChevronDownIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
            </span>
          </Button>
        </div>
        <div className="hidden lg:block">
          <PaymentFilters
            defaultFilters={{}}
            typedSearchParams={typedSearchParams}
            setTypedSearchParams={setTypedSearchParams}
            selectedOrganization={selectedOrganization}
            organizationList={organizationList}
            paymentMethod={paymentMethod}
            pspReferenceId={pspReferenceId}
            source={source}
            status={status}
            dateValue={dateValue}
            customAttributesNames={customAttributesNames}
            handleDateChange={handleDateChange}
            paymentMethodsFilters={paymentMethodsFilters}
            selectedMerchantAccount={selectedMerchantAccount}
            merchantAccounts={merchantAccounts}
          />
        </div>
        <SlideOverWithBrandedHeader
          isOpen={isFiltersOpen}
          title={'Filters'}
          closeHandler={() => setIsFiltersOpen(false)}
          panelClassName="w-screen pointer-events-auto lg:max-w-md"
        >
          <PaymentFilters
            defaultFilters={{}}
            typedSearchParams={typedSearchParams}
            setTypedSearchParams={setTypedSearchParams}
            selectedOrganization={selectedOrganization}
            organizationList={organizationList}
            paymentMethod={paymentMethod}
            pspReferenceId={pspReferenceId}
            source={source}
            status={status}
            dateValue={dateValue}
            customAttributesNames={customAttributesNames}
            handleDateChange={handleDateChange}
            paymentMethodsFilters={paymentMethodsFilters}
            selectedMerchantAccount={selectedMerchantAccount}
            merchantAccounts={merchantAccounts}
          />
        </SlideOverWithBrandedHeader>
      </div>
      <div className="mt-4 overflow-x-auto">
        <PaymentsList
          paymentsList={paymentList.data}
          filters={typedSearchParams}
          setFilters={setTypedSearchParams}
          sort={sort}
          setSort={setSort}
          pagination={pagination}
          onPaginationChange={onPaginationChange}
          openDetailsPanel={handleOpenSidePanel}
          showOrganizationColumn
          showProfit={true}
          customAttributes={customAttributesNames}
          isLoading={
            paymentList.isLoading || (paymentList.isRefetching && paymentList.isPreviousData)
          }
        />
      </div>
      <PaymentDetailsPanel
        isOpen={isSidePanelOpen}
        onCancel={closeSidePanel}
        paymentDetailsRow={paymentDetailsRow}
        trpc={trpc}
        onViewDetailsClick={handleOpenPaymentDetailsPage}
        showOrganizationColumn
        showProfit
      />
    </div>
  );
};
