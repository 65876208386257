import {trpc} from '@/api/trpcClient';
import {RoutePath} from '@/components/layout/navigation';
import {useStore} from '@/store';
import {Trans, t} from '@lingui/macro';
import {MerchantAccountsListItem} from '@zentact/api/src/trpc/routers/merchantAccountRouter';
import {formatLocaleDate} from '@zentact/common';
import {
  Button,
  MerchantApplicationFileDownload,
  SlideOverWithBrandedHeader,
} from '@zentact/ui-tailwind';
import {Link, generatePath, useNavigate} from 'react-router-dom';

type MerchantViewPanelProps = {
  isOpen: boolean;
  onCancel: () => void;
  merchantAccountRow: MerchantAccountsListItem | null;
};

export const MerchantViewPanel = ({
  isOpen,
  onCancel,
  merchantAccountRow,
}: MerchantViewPanelProps) => {
  const navigate = useNavigate();
  const {tenant} = useStore();

  if (!merchantAccountRow) {
    return null;
  }

  const merchantAccountId = merchantAccountRow.merchantAccount?.id;
  const {
    inviteeEmail,
    updatedAt,
    organization,
    legalBusinessName,
    sessionId,
    uploadedDocumentName,
    merchantAccount,
  } = merchantAccountRow;

  const splitConfigurationGroup = merchantAccountRow.merchantAccount?.splitConfigurationGroup;
  const applicationDocumentFieldName =
    tenant?.features?.askForDocumentsInMerchantApplication?.title;

  return (
    <SlideOverWithBrandedHeader
      isOpen={isOpen}
      closeHandler={onCancel}
      title={t`View Merchant Details`}
      footer={
        merchantAccountId ? (
          <footer className="flex justify-end p-4 shrink-0">
            <Button
              variant="primary"
              size="lg"
              className="w-fit"
              onClick={() =>
                navigate(
                  generatePath(RoutePath.MERCHANT_DETAILS, {
                    merchantAccountId,
                  })
                )
              }
            >
              <Trans>View Details</Trans>
            </Button>
          </footer>
        ) : undefined
      }
    >
      <>
        <div className="border-gray-100">
          <dl className="divide-y divide-gray-100">
            <div className="pb-3 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                <Trans>Legal Business Name</Trans>
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 break-words sm:col-span-2 sm:mt-0">
                {legalBusinessName}
              </dd>
            </div>
            {!!merchantAccount?.id && (
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  <Trans>Merchant Account ID</Trans>
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 break-words sm:col-span-2 sm:mt-0">
                  {merchantAccount.id}
                </dd>
              </div>
            )}
            {!!merchantAccount?.tenantMerchantReferenceId && (
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  <Trans>Merchant Reference ID</Trans>
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 break-words sm:col-span-2 sm:mt-0">
                  {merchantAccount.tenantMerchantReferenceId}
                </dd>
              </div>
            )}
            {!!organization && (
              <>
                <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    <Trans>Organization</Trans>
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 break-words sm:col-span-2 sm:mt-0">
                    {organization.name}
                  </dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    <Trans>Organization ID</Trans>
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 break-words sm:col-span-2 sm:mt-0">
                    {organization.id}
                  </dd>
                </div>
              </>
            )}
            {inviteeEmail && (
              <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  <Trans>Contact Email</Trans>
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 break-words sm:col-span-2 sm:mt-0">
                  {inviteeEmail}
                </dd>
              </div>
            )}
            {!!splitConfigurationGroup && (
              <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  <Trans>Fee Group</Trans>
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 break-words sm:col-span-2 sm:mt-0">
                  <Link
                    to={generatePath(RoutePath.FEE_GROUP_DETAILS, {
                      splitConfigurationGroupId: splitConfigurationGroup.id,
                    })}
                    className="text-primary-600 hover:underline"
                  >
                    {splitConfigurationGroup.name}
                  </Link>
                </dd>
              </div>
            )}
            {!!applicationDocumentFieldName && !!uploadedDocumentName && (
              <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  {applicationDocumentFieldName}
                </dt>
                <dd className="flex items-center mt-1 text-sm leading-6 text-gray-700 break-words sm:col-span-2 sm:mt-0">
                  <div className="flex items-center gap-x-4">
                    {uploadedDocumentName}
                    <MerchantApplicationFileDownload
                      registrationSessionId={sessionId}
                      trpc={trpc}
                    />
                  </div>
                </dd>
              </div>
            )}
            <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                <Trans>Last Updated At</Trans>
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 break-words sm:col-span-2 sm:mt-0">
                {formatLocaleDate(updatedAt)}
              </dd>
            </div>
          </dl>
        </div>
      </>
    </SlideOverWithBrandedHeader>
  );
};
