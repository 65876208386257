import {I18n} from '@lingui/core';
import {Trans} from '@lingui/macro';
import {createColumnHelper} from '@tanstack/react-table';
import {MerchantStoppedProcessingListItem} from '@zentact/api/src/trpc/routers/reportingRouter';
import {
  CurrencyCode,
  LocaleCode,
  TableColumnSize,
  formatAmount,
  formatLocaleDate,
} from '@zentact/common';
import {
  FlatPillWithDot,
  HighlightedText,
  TableSearchFilter,
  Tooltip,
  TruncatedText,
  getLocalizedStatusMap,
  getTableMeta,
  merchantAccountStatusToColor,
  truncateText,
} from '@zentact/ui-tailwind';
import {DateTime} from 'luxon';

const columnsHelper = createColumnHelper<MerchantStoppedProcessingListItem>();

type Props = {
  i18n: I18n;
  locale: LocaleCode;
};
export const getMerchantStoppedProcessingListColumns = ({i18n, locale}: Props) => {
  const statusLabelMap = getLocalizedStatusMap(i18n);

  return [
    columnsHelper.display({
      cell: props => <TruncatedText text={props.row.original.organizationName} />,
      header: () => <Trans>Organization</Trans>,
      id: 'organizationName',
      size: TableColumnSize.L,
    }),
    columnsHelper.display({
      cell: props => {
        const {filterValues} = getTableMeta(props.table);
        const searchString = filterValues?.businessName as string;
        const textContent = props.row.original.businessName || '-';

        return textContent.length > 20 ? (
          <Tooltip text={textContent}>
            <HighlightedText text={truncateText(textContent)} highlight={searchString} />
          </Tooltip>
        ) : (
          <HighlightedText text={textContent} highlight={searchString} />
        );
      },
      id: 'businessName',
      header: () => <Trans>Merchant Account</Trans>,
      meta: {
        filter: {
          filterId: 'businessName',
          renderFilter: ({...filterProps}) => <TableSearchFilter {...filterProps} />,
        },
        collapseAt: 'md',
      },
    }),
    columnsHelper.accessor('status', {
      cell: ({row}) => (
        <FlatPillWithDot
          color={merchantAccountStatusToColor[row.original.status] || 'blue'}
          label={statusLabelMap[row.original.status] || row.original.status}
        />
      ),
      meta: {
        collapseAt: 'lg',
      },
      header: () => <Trans>Account Status</Trans>,
      size: TableColumnSize.XS,
    }),
    columnsHelper.display({
      cell: props =>
        Math.floor(
          DateTime.now().diff(DateTime.fromISO(props.row.original.lastPaymentDate), 'days').days
        ),
      header: () => <Trans>Days Inactive</Trans>,
      id: 'staleDays',
      meta: {
        sort: {
          isSortable: true,
        },
        visibleAt: '2xl',
      },
      size: TableColumnSize.XXS,
    }),
    columnsHelper.accessor('lastPaymentDate', {
      cell: info => formatLocaleDate(info.getValue(), 'shortWithTime'),
      header: () => <Trans>Last Processing Date</Trans>,
      meta: {
        sort: {
          isSortable: true,
        },
      },
      size: TableColumnSize.M,
    }),
    columnsHelper.accessor('firstPaymentDate', {
      cell: info => formatLocaleDate(info.getValue(), 'shortWithTime'),
      header: () => <Trans>First Processing Date</Trans>,
      meta: {
        sort: {
          isSortable: true,
        },
        visibleAt: 'sm',
      },
      size: TableColumnSize.M,
    }),
    columnsHelper.accessor('lastMonthVolume', {
      cell: ({row}) =>
        formatAmount(row.original.lastMonthVolume, locale, row.original.currency as CurrencyCode),
      header: () => <Trans>Last Month Volume</Trans>,
      meta: {
        visibleAt: '2xl',
      },
      size: TableColumnSize.M,
    }),
  ];
};
