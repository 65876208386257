import {setAccessToken as setTRPCAccessToken} from '@/api/trpcClient';
import {useStore} from '@/store';
import {AsyncState} from '@/types/AsyncState';
import {useAuth0} from '@auth0/auth0-react';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

export const useAccessToken = () => {
  const {isAuthenticated, getAccessTokenSilently, getIdTokenClaims, user} = useAuth0();
  const {setAccessToken: setStoreAccessToken} = useStore();
  const navigate = useNavigate();

  const [accessToken, setAccessToken] = useState<AsyncState<string | undefined>>({
    isLoading: true,
    isError: false,
  });

  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently()
        .then(token => {
          setTRPCAccessToken(token);
          setStoreAccessToken(token);
          setAccessToken({isLoading: false, isError: false, data: token});
        })
        .catch(error => {
          setAccessToken({
            isLoading: false,
            isError: true,
            error: {code: error.code, message: error.message},
          });
        });
    } else {
      setAccessToken({isLoading: false, isError: false, data: undefined});
    }
  }, [isAuthenticated, getAccessTokenSilently]);

  // Temp fix to log out users when we change the auth0 domain
  useEffect(() => {
    if (!user) return;

    getIdTokenClaims().then(claims => {
      if (!claims?.iss || claims.iss === `https://${import.meta.env.VITE_AUTH0_DOMAIN}/`) return;
      setTimeout(() => {
        navigate('/logout');
      }, 1000);
    });
  }, [user]);

  return {accessToken};
};
