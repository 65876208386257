import {Trans} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import {
  MerchantAccountDetailsOutput,
  MerchantAccountsListItem,
  MerchantAccountsListOutput,
} from '@zentact/api/src/trpc/routers/merchantAccountRouter';
import {TableSortValue, TableWrapper} from '@zentact/ui-tailwind';
import {Dispatch, SetStateAction} from 'react';
import {getMerchantListColumns} from './columns';
import {MerchantReviewPanelProps} from './merchant-review-panel';

type Props<TFilters extends Record<string, unknown>> = {
  merchantList?: MerchantAccountsListOutput;
  filters?: TFilters;
  setFilters?: (params: TFilters) => void;
  sort: TableSortValue<string> | null;
  setSort: Dispatch<SetStateAction<TableSortValue<string> | null>>;
  openDetailsPanel?: (row: MerchantAccountsListItem) => void;
  openReviewPanel?: (row: MerchantReviewPanelProps['merchantAccountRow']) => void;
  openUpdateSettlementDelayDays?: (
    row: MerchantAccountDetailsOutput | MerchantAccountsListItem | null
  ) => void;
  openAssignMerchantFeeGroupPanel?: (
    row: MerchantAccountDetailsOutput | MerchantAccountsListItem | null
  ) => void;
  pagination?: {pageIndex: number; pageSize: number};
  onPaginationChange?: Dispatch<SetStateAction<{pageIndex: number; pageSize: number}>>;
  refetch: () => void;
  mode?: 'onlyPending';
  hideMerchantSearch?: boolean;
  hideAllSorting?: boolean;
  isLoading?: boolean;
};

export const MerchantsList = <TFilters extends Record<string, unknown>>({
  merchantList,
  filters,
  setFilters,
  sort,
  setSort,
  pagination,
  onPaginationChange,
  refetch,
  mode,
  hideMerchantSearch,
  hideAllSorting,
  openDetailsPanel,
  openReviewPanel,
  openUpdateSettlementDelayDays,
  openAssignMerchantFeeGroupPanel,
  isLoading,
}: Props<TFilters>) => {
  const {i18n} = useLingui();
  return (
    <TableWrapper
      data={merchantList?.rows ?? []}
      pageCount={merchantList?.pagination.pageCount ?? 0}
      columns={getMerchantListColumns({
        refetchMerchantList: refetch,
        mode,
        hideMerchantSearch,
        hideAllSorting,
        openDetailsPanel,
        openReviewPanel,
        openUpdateSettlementDelayDays,
        openAssignMerchantFeeGroupPanel,
        i18n,
      })}
      filterValues={filters}
      onFilterChange={({id, value}) => {
        if (setFilters) {
          setFilters({[id]: value} as TFilters);
        }
      }}
      sortValue={!hideAllSorting ? sort : undefined}
      onSortChange={setSort}
      noDataMessage={
        <div>
          <h3 className="text-sm font-semibold text-gray-900">
            <Trans>No Merchants Waiting</Trans>
          </h3>
          <span className="text-sm text-gray-500">
            <Trans>
              As merchants register, they will appear here for you to review and approve.
            </Trans>
          </span>
        </div>
      }
      pagination={pagination}
      onPaginationChange={onPaginationChange}
      onRowClick={openDetailsPanel || openReviewPanel}
      excludeLastColumnFromRowClick
      isLoading={isLoading}
    />
  );
};
