import {i18n} from '@lingui/core';
import {t} from '@lingui/macro';
import {AddTenantWebhookRequest} from '@zentact/core';
import {
  InputCheckboxList,
  InputText,
  Label,
  Typography,
  ValidationError,
} from '@zentact/ui-tailwind';
import {Controller, UseFormReturn} from 'react-hook-form';
import {isURL} from 'validator';
import {z} from 'zod';

type WebhookFormProps = {
  form: UseFormReturn<AddTenantWebhookRequest>;
  initialValues?: {email?: string};
  children?: JSX.Element;
};

export const addWebhookFormSchema = () =>
  z.object({
    name: z.string().min(3).max(255),
    url: z
      .string()
      .url()
      .refine(url => {
        // biome-ignore lint/style/useNamingConvention: <explanation>
        return isURL(url, {require_protocol: true, require_tld: true});
      }),
    eventTypes: z
      .array(z.enum(['SAVED_PAYMENT_METHOD', 'PAYMENT', 'MERCHANT']), {
        // biome-ignore lint/style/useNamingConvention: <explanation>
        required_error: t`At least one event type must be selected`,
      })
      .min(1, {message: t`At least one event type must be selected`}),
  });

export const AddWebhookForm = ({form, children}: WebhookFormProps) => {
  const {
    register,
    control,
    formState: {errors},
  } = form;

  return (
    <div className="flex flex-col gap-3">
      <Label text={t`Name`}>
        <InputText {...register('name', {required: true})} hasError={Boolean(errors.name)} />
        <ValidationError isVisible={Boolean(errors.name)}>{errors.name?.message}</ValidationError>
      </Label>
      <Label text={t`URL`}>
        <InputText {...register('url')} hasError={Boolean(errors.url)} />
        <ValidationError isVisible={Boolean(errors.url)}>{errors.url?.message}</ValidationError>
      </Label>
      <Typography variant="header-form-section" className="mt-2 font-normal">
        {i18n._('Event Types')}
      </Typography>
      <ValidationError isVisible={Boolean(errors.eventTypes)}>
        {errors.eventTypes?.message}
      </ValidationError>
      <Controller
        name="eventTypes"
        control={control}
        render={({field}) => (
          <InputCheckboxList
            options={[
              {
                id: 'PAYMENT',
                label: t`Payment`,
              },
              {
                id: 'MERCHANT',
                label: t`Merchant`,
              },
              {
                id: 'SAVED_PAYMENT_METHOD',
                label: t`Saved Payment Method`,
              },
            ]}
            getLabel={option => option.label}
            getOptionId={option => option.id}
            value={field.value}
            onChange={field.onChange}
            searchPlaceholder={i18n._('Event Type')}
            className="mb-2"
          />
        )}
      />
      {children}
    </div>
  );
};
