import {PaginationState} from '@tanstack/react-table';
import {SetStateAction} from 'react';

// returns interlayer function to connect the setter function from "useTypedSearchParams" and onPaginationChange from TanStack Table lib
export const createSetPaginationCallback = (
  current: PaginationState,
  setTypedSearch: (v: {pageIndex?: string}) => void
) => {
  return (dataOrCallback: SetStateAction<PaginationState>) => {
    const params =
      typeof dataOrCallback === 'function'
        ? (dataOrCallback as (prevState: PaginationState) => PaginationState)(current)
        : dataOrCallback;
    setTypedSearch({pageIndex: params?.pageIndex ? params.pageIndex.toString() : undefined});
  };
};
