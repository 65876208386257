import {t} from '@lingui/macro';
import {InputText, Label, ValidationError} from '@zentact/ui-tailwind';
import {UseFormReturn} from 'react-hook-form';
import z from 'zod';

export const addApiKeyFormSchema = () =>
  z.object({
    name: z.string().min(3).max(255),
  });

export type ApiKeyFormData = z.infer<ReturnType<typeof addApiKeyFormSchema>>;

type MerchantAccountFormProps = {
  form: UseFormReturn<ApiKeyFormData>;
  initialValues?: {email?: string};
  children?: JSX.Element;
};

export const AddApiKeyForm = ({form, children}: MerchantAccountFormProps) => {
  const {
    register,
    formState: {errors},
  } = form;

  return (
    <div className="flex flex-col gap-3">
      <Label text={t`Name`}>
        <InputText {...register('name', {required: true})} hasError={Boolean(errors.name)} />
        <ValidationError isVisible={Boolean(errors.name)}>{errors.name?.message}</ValidationError>
      </Label>
      {children}
    </div>
  );
};
