import {trpc} from '@/api/trpcClient';
import {RoutePath} from '@/components/layout/navigation';
import {useStore} from '@/store';
import {ChevronDownIcon} from '@heroicons/react/20/solid';
import {Trans, plural, t} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import {CurrencyCode, formatLocaleDate} from '@zentact/common';
import {
  Breadcrumbs,
  Button,
  ResetTableFiltersButton,
  SlideOverWithBrandedHeader,
  Summary,
  SummaryItemDataType,
  SummaryItemPropType,
  TableListSort,
  TableSort,
  Typography,
  createSetPaginationCallback,
  getDashboardDatepickerDefaults,
  useNotification,
  useToggleWithData,
  useTypedSearchParams,
} from '@zentact/ui-tailwind';
import {useCallback, useState} from 'react';
import {exportOtherRevenueCSV} from './export-other-revenue-csv';
import {OtherRevenueFilters} from './other-revenue-filters';
import {OtherRevenueList} from './other-revenue-list';
import {
  OtherRevenueViewPanel,
  OtherRevenueViewPanelProps,
} from './other-revenue-list/other-revenue-view-panel';
import {otherRevenueSearchParamsSchema} from './other-revenue-search-params-schema';

const getBreadCrumbs = () => [
  {name: t`Reports`, href: RoutePath.REPORTS, current: false, disabled: true},
  {name: t`Other Revenue Report`, href: RoutePath.REPORTS_OTHER_REVENUE, current: true},
];

const pageSize = 25;

export const OtherRevenueReportPage = () => {
  const {showSuccessNotification, showErrorNotification} = useNotification();
  const {i18n} = useLingui();
  const {locale} = useStore();
  const [isCsvLoading, setCsvLoading] = useState(false);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const {typedSearchParams, setTypedSearchParams} = useTypedSearchParams(
    otherRevenueSearchParamsSchema
  );

  const selectedOrganization = typedSearchParams.organizationId;
  const selectedMerchant = typedSearchParams.merchantAccountId;
  const startDate = typedSearchParams?.startDate;
  const endDate = typedSearchParams?.endDate;
  const dateValue = startDate && endDate ? {startDate, endDate} : getDashboardDatepickerDefaults();

  const pagination = {
    pageIndex: typedSearchParams.pageIndex ? Number(typedSearchParams.pageIndex) : 0,
    pageSize,
  };
  const setPagination = createSetPaginationCallback(pagination, setTypedSearchParams);

  const sort =
    typedSearchParams.sortColumnId && typedSearchParams.sortValue
      ? {
          columnId: typedSearchParams.sortColumnId,
          value: typedSearchParams.sortValue,
        }
      : null;
  const trpcContext = trpc.useUtils();
  const {data: otherRevenueListData, isLoading: organizationRevenueListLoading} =
    trpc.reporting.getOtherRevenueList.useQuery(
      {
        ...pagination,
        where: {
          fromDate: dateValue.startDate,
          toDate: dateValue.endDate,
          ...(selectedOrganization && {organizationId: selectedOrganization}),
          ...(selectedMerchant && {merchantAccountId: selectedMerchant}),
          ...(typedSearchParams.type?.length && {type: typedSearchParams.type}),
        },
        orderBy: sort,
      },
      {keepPreviousData: true}
    );

  const {data: summaryData, isLoading: summaryIsLoading} =
    trpc.reporting.getOtherRevenueSummary.useQuery({
      fromDate: dateValue.startDate,
      toDate: dateValue.endDate,
      ...(selectedOrganization && {organizationId: selectedOrganization}),
      ...(selectedMerchant && {merchantAccountId: selectedMerchant}),
    });

  const currency = (summaryData?.currency || CurrencyCode.USD) as CurrencyCode;

  const summaryItems: SummaryItemPropType[] = [
    {
      title: t`Total Fees Revenue`,
      value: summaryData
        ? summaryData.monthlyFeesVolume +
          summaryData.annualFeesVolume +
          summaryData.chargebackFeesVolume +
          summaryData.refundFeesVolume
        : 0,
      type: SummaryItemDataType.CURRENCY,
      currency,
    },
    {
      title:
        summaryData?.monthlyFeesCount && summaryData?.annualFeesCount
          ? t`${summaryData.monthlyFeesCount} Monthly and ${summaryData.annualFeesCount} Annual Fees`
          : summaryData?.monthlyFeesCount
            ? plural(summaryData.monthlyFeesCount, {
                one: '# Monthly Fee',
                other: '# Monthly Fees',
              })
            : summaryData?.annualFeesCount
              ? plural(summaryData.annualFeesCount, {
                  one: '# Annual Fee',
                  other: '# Annual Fees',
                })
              : t`0 Annual and Monthly Fees`,
      value: summaryData ? summaryData.monthlyFeesVolume + summaryData.annualFeesVolume : 0,
      type: SummaryItemDataType.CURRENCY,
      currency,
    },
    {
      title: plural(summaryData?.chargebackFeesCount ?? 0, {
        one: '# Chargeback Fee',
        other: '# Chargeback Fees',
      }),
      value: summaryData?.chargebackFeesVolume,
      type: SummaryItemDataType.CURRENCY,
      currency,
    },
    {
      title: plural(summaryData?.refundFeesCount ?? 0, {
        one: '# Refund Fee',
        other: '# Refund Fees',
      }),
      value: summaryData?.refundFeesVolume,
      type: SummaryItemDataType.CURRENCY,
      currency,
    },
  ];

  const setSort = useCallback(
    (v: {columnId: string; value: TableSort} | null) => {
      if (!v) {
        setTypedSearchParams({sortColumnId: undefined, sortValue: undefined});
        return;
      }
      setTypedSearchParams({sortColumnId: v.columnId, sortValue: v.value});
    },
    [setTypedSearchParams]
  );

  const handleCsvExport = useCallback(async () => {
    setCsvLoading(true);
    try {
      const fullList = await trpcContext.reporting.getOtherRevenueList.fetch({
        where: {
          fromDate: dateValue.startDate,
          toDate: dateValue.endDate,
          ...(selectedOrganization && {organizationId: selectedOrganization}),
          ...(selectedMerchant && {merchantAccountId: selectedMerchant}),
          ...(typedSearchParams?.type?.length && {type: typedSearchParams.type}),
        },
        orderBy: sort,
      });
      exportOtherRevenueCSV(fullList, dateValue.startDate, dateValue.endDate, i18n);
      showSuccessNotification(t`Other Revenue .csv file exported`);
    } catch (e) {
      showErrorNotification(t`Other Revenue .csv export failed`, (e as Error).message);
    }
    setCsvLoading(false);
  }, [setCsvLoading, trpcContext, dateValue, i18n, selectedOrganization]);

  const {
    isOpen: isOtherRevenueDetailsOpen,
    data: otherRevenueViewRow,
    on: openOtherRevenuePanel,
    off: closeOtherRevenuePanel,
  } = useToggleWithData<OtherRevenueViewPanelProps['row']>();
  return (
    <>
      <Breadcrumbs pages={getBreadCrumbs()} />
      <div className="flex justify-between gap-2 pt-4 max-md:flex-col md:items-center">
        <Typography variant="header-page">
          <Trans>Other Revenue Report For</Trans> {formatLocaleDate(dateValue.startDate, 'short')}
          {' - '}
          {formatLocaleDate(dateValue.endDate, 'short')}
        </Typography>
        <Button
          type="button"
          variant="primary"
          size="md"
          className="w-fit max-md:w-full"
          isLoading={isCsvLoading}
          onClick={handleCsvExport}
          disabled={!otherRevenueListData || otherRevenueListData.rows.length === 0}
        >
          <Trans>Export to CSV</Trans>
        </Button>
      </div>
      <div className="mt-4 text-sm leading-6 text-gray-500">
        <Trans>
          This report highlights the revenue that came from monthly and annual fees, along with
          chargeback and refund fees.
        </Trans>
      </div>
      <div className="flex justify-between gap-2 mt-4 font-normal max-sm:flex-wrap sm:items-center">
        <TableListSort
          options={[
            {
              id: 'createdAt',
              label: i18n._('Date'),
            },
            {
              id: 'amount',
              label: i18n._('Amount'),
            },
          ]}
          sort={sort}
          setSort={setSort}
        />
        <div className="flex justify-between gap-2">
          <div className="lg:hidden">
            <ResetTableFiltersButton
              defaultFilters={{}}
              activeFilters={typedSearchParams}
              setFilters={setTypedSearchParams}
              ignoreKeys={[
                'sortColumnId',
                'sortValue',
                'pageIndex',
                'endDate', // count only startDate to show the date as a single filter
              ]}
            />
          </div>
          <Button
            type="button"
            size="sm"
            onClick={() => setIsFiltersOpen(true)}
            className="lg:hidden w-fit relative min-h-[2.25rem] shadow-none cursor-pointer font-semibold rounded-md py-1.5 pl-3 pr-10 text-left focus:outline-none text-gray-700 disabled:bg-slate-100 bg-transparent hover:bg-transparent focus:bg-transparent active:bg-transparent sm:text-sm sm:leading-6 border-none"
          >
            <Trans>Filter</Trans>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 ml-3 pointer-events-none">
              <ChevronDownIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
            </span>
          </Button>
        </div>
        <div className="hidden lg:block">
          <OtherRevenueFilters
            typedSearchParams={typedSearchParams}
            setTypedSearchParams={setTypedSearchParams}
          />
        </div>
        <SlideOverWithBrandedHeader
          isOpen={isFiltersOpen}
          title={'Filters'}
          closeHandler={() => setIsFiltersOpen(false)}
          panelClassName="w-screen pointer-events-auto lg:max-w-md"
        >
          <OtherRevenueFilters
            typedSearchParams={typedSearchParams}
            setTypedSearchParams={setTypedSearchParams}
          />
        </SlideOverWithBrandedHeader>
      </div>
      <Summary summaryItems={summaryItems} isLoading={summaryIsLoading} locale={locale} />
      <div className="mt-4">
        <OtherRevenueList
          data={otherRevenueListData}
          sort={sort}
          setSort={setSort}
          isLoading={organizationRevenueListLoading}
          pagination={pagination}
          onPaginationChange={setPagination}
          openDetailsPanel={openOtherRevenuePanel}
        />
      </div>
      <OtherRevenueViewPanel
        isOpen={isOtherRevenueDetailsOpen}
        onCancel={closeOtherRevenuePanel}
        row={otherRevenueViewRow}
      />
    </>
  );
};
