import {trpc} from '@/api/trpcClient';
import {useLocale} from '@/hooks/useLocale';
import {DEFAULT_CURRENCY} from '@zentact/common';
import {useMemo, useState} from 'react';
import {createContainer} from 'unstated-next';

const Store = createContainer(() => {
  const {localeState, locale} = useLocale();
  const [accessToken, setAccessToken] = useState<string | undefined>(undefined);
  const tenant = trpc.tenant.getContextTenant.useQuery(undefined, {enabled: !!accessToken});
  const organizationList = trpc.organization.getActiveOrganizations.useQuery(undefined, {
    enabled: !!accessToken,
    initialData: [],
  });
  const orgsWithBoardedMerchants = useMemo(() => {
    return organizationList.data.filter(o => o.hasBoardedMerchants);
  }, [organizationList.data]);
  const merchantAccounts = trpc.merchantAccount.getAllMerchantAccounts.useQuery(undefined, {
    enabled: !!accessToken,
    refetchOnWindowFocus: true,
    initialData: [],
  });

  const activeAndInactiveMerchantAccounts = useMemo(() => {
    return merchantAccounts.data.filter(
      merchant => merchant.status === 'ACTIVE' || merchant.status === 'INACTIVE'
    );
  }, [merchantAccounts.data]);

  return {
    isLoading: localeState.isLoading || tenant.isLoading || organizationList.isLoading,
    isError: localeState.isError || tenant.isError || organizationList.isError,
    tenant: {...tenant.data, currency: tenant.data?.currency ?? DEFAULT_CURRENCY},
    tenantRefetch: tenant.refetch,
    organizationList: organizationList.data,
    merchantAccounts: merchantAccounts.data,
    activeAndInactiveMerchantAccounts,
    orgsWithBoardedMerchants,
    refetchOrganizationList: organizationList.refetch,
    locale,
    setAccessToken,
  };
});

export const useStore = () => {
  return Store.useContainer();
};

export const StoreProvider = Store.Provider;
