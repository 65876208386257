import {Switch} from '@headlessui/react';
import {useLingui} from '@lingui/react';
import {createTRPCReact} from '@trpc/react-query';
import type {ServerRouter} from '@zentact/api';
import {useCallback, useState} from 'react';
import {cn, useNotification} from '../..';
import {IconSpinner} from '../../icons/spinner';

type Props = {
  trpc: ReturnType<typeof createTRPCReact<ServerRouter>>;
  merchantAccountId: string;
  businessName: string;
  payoutsEnabled: boolean;
  refetch: () => void;
};

export const MerchantPayoutEnableToggle = ({
  trpc,
  payoutsEnabled,
  merchantAccountId,
  businessName,
  refetch,
}: Props) => {
  const i18n = useLingui();
  const {showSuccessNotification, showErrorNotification} = useNotification();
  const [isLoading, setLoading] = useState(false);

  const enableMerchantPayouts = trpc.merchantAccount.enableMerchantPayouts.useMutation({
    onSuccess: () => {
      setLoading(false);
      refetch();
      showSuccessNotification(i18n._('Payouts for {businessName} enabled', {businessName}));
    },
    onError: () => {
      setLoading(false);
      showErrorNotification(i18n._('Failed to enable payouts'));
    },
  });
  const disableMerchantPayouts = trpc.merchantAccount.disableMerchantPayouts.useMutation({
    onSuccess: () => {
      setLoading(false);
      refetch();
      showSuccessNotification(i18n._('Payouts for {businessName} disabled', {businessName}));
    },
    onError: () => {
      setLoading(false);
      showErrorNotification(i18n._('Failed to disable payouts'));
    },
  });

  const onToggleChange = useCallback(
    async (enable: true) => {
      setLoading(true);
      if (enable) {
        enableMerchantPayouts.mutate({merchantAccountId});
      } else {
        disableMerchantPayouts.mutate({merchantAccountId});
      }
    },
    [enableMerchantPayouts, disableMerchantPayouts]
  );

  return (
    <Switch.Group as="div" className="flex items-center">
      <Switch
        checked={payoutsEnabled}
        onChange={onToggleChange}
        className={cn(
          payoutsEnabled && !isLoading ? 'bg-primary-500' : 'bg-gray-200',
          'focus:ring-primary-500 relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2'
        )}
        disabled={isLoading}
      >
        {isLoading ? (
          <div className={cn('flex items-center justify-center', payoutsEnabled && 'pl-5')}>
            <IconSpinner />
          </div>
        ) : (
          <span
            aria-hidden="true"
            className={cn(
              isLoading && 'hidden',
              payoutsEnabled ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
            )}
          />
        )}
      </Switch>
      <Switch.Label as="span" className="ml-3 text-sm">
        <span className="font-medium text-gray-900">{i18n._('Enable Payouts')}</span>
      </Switch.Label>
    </Switch.Group>
  );
};
